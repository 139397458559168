import React, { useEffect, useMemo, useState } from "react";
import PageHeading from "../../components/PageHeading";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../components/btn/Buttons";
import Button from "../../components/btn/Button";
import PageLoading from "../../components/PageLoading";
import NoData from "../../components/NoData";
import { Pagination2 } from "../../components/pagination/pagination";
import { BankCodeFinder, DeleteIcon } from "../../utils/utils";
import RowTxt from "../../components/RowTxt";
import { motion } from "framer-motion";
import { HiOutlineArrowRight } from "react-icons/hi";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import Modal from "../../components/modal/Modal";
import { useSelector } from "react-redux";
import { DropInput } from "../../components/inputs/DropInput";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import { get_bank_master_list } from "../MasterData/masterdataSlice";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
import store from "../../redux/store";
import { DateValidator2 } from "../../utils/validation";
import moment from "moment";
import { Alerts } from "../../components/Alerts/Alerts";
import { get_cheque_list } from "./cashBankSlice";
import { cheque_list_API } from "../../utils/apis";
import { toast } from "react-toastify";
import Table from "../../components/table/Table";

const Cheque = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [addDataHead, setAddDataHead] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cheque_list_drop, setCheque_list_drop] = useState([]);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );
  const { user_id, user_turnover, today } = useSelector((state) => state.other);
  const { bank_master_list } = useSelector((state) => state.masterdata);
  const { profile_data, day_end_date } = useSelector((state) => state.profile);
  const { cheque_list, cheque_loading } = useSelector(
    (state) => state.cashbanks
  );
  useEffect(() => {
    store.dispatch(get_bank_master_list());
    store.dispatch(get_transition_complete_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_cheque_list(obj));
  }, [entries, pageNo]);

  const onDelete_item = async () => {
    try {
      setOpen(false);
      setLoading(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      const responce = await cheque_list_API(body);
      setLoading(false);
      if (responce.success) {
        store.dispatch(get_cheque_list(obj));
        toast(responce?.message);
      } else if (!responce?.success) {
        setMess(responce?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log("err ====", error);
    }
  };
  const get_cheque_list_drop = async (value) => {
    if (value?.length > 0 && value) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "fetchcheque");
        body.append("agencyid", user_id);
        body.append("customer_id", value);
        const res = await cheque_list_API(body);
        setLoading(false);
        if (res.success) {
          setCheque_list_drop(res.cheque);
        } else if (!res.success) {
          setCheque_list_drop([]);
          setOpen1(true);
          setMess(res.message);
        }
      } catch (error) {
        console.log("error ==", error);
      }
    }
  };

  const valiDate = () => {
    const data = [];
    const date = moment(addDataHead?.cheque_date, "YYYY-MM-DD");
    date.add(90, "days");
    const NinghtyformattedDate = date.format("DD/MM/YYYY");

    const dateActual = moment(NinghtyformattedDate, "DD/MM/YYYY").isAfter(
      moment(today, "DD/MM/YYYY")
    )
      ? today
      : NinghtyformattedDate;

    const data1 = DateValidator2(
      moment(addDataHead?.cheque_deposite_date).format("DD/MM/YYYY"),
      dateActual,
      addDataHead?.cheque_date,
      `Cheque Deposite date cant be before as Cheque Date ${addDataHead?.cheque_date}`,
      `Cheque Deposite date cant be a future date`
    );
    if (
      !addDataHead.customer_id ||
      (addDataHead.customer_id && addDataHead.customer_id.length == 0)
    ) {
      data.push("Please Enter Customer Name First");
    }
    if (
      !addDataHead.cheque_deposite_date ||
      (addDataHead.cheque_deposite_date &&
        addDataHead.cheque_deposite_date.length != 10)
    ) {
      data.push("Please Enter Invoice Date First");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead.bank_account ||
      (addDataHead.bank_account && addDataHead.bank_account.length == 0)
    ) {
      data.push("Please Select DEBITED/CREDITED TO");
    }
    if (
      !addDataHead.status ||
      (addDataHead.status && addDataHead.status.length == 0)
    ) {
      data.push("Please Select Status");
    }
    if (
      !addDataHead.narration ||
      (addDataHead.narration && addDataHead.narration.length == 0)
    ) {
      data.push("Plaese Enter Narration ");
    }

    return data;
  };
  const add_items = async (type) => {
    const data1 = await valiDate();
    const data = [...new Set(data1)];

    if (data?.length == 0) {
      setLoading(true);
      const body = new FormData();
      body.append("action", "update");
      body.append("agencyid", user_id);
      body.append("id", addDataHead?.cheque_no);
      body.append("payment_type", addDataHead?.payment_type);
      body.append("cheque_date", addDataHead?.cheque_date);
      body.append("cleared_date", addDataHead?.cheque_deposite_date);
      body.append("amount", addDataHead?.cheque_value);
      body.append("bank_id", addDataHead?.bank_account);
      body.append("clearance_status", addDataHead?.status);
      body.append("narration", addDataHead?.narration);
      const res = await cheque_list_API(body);
      setLoading(false);
      if (res.success) {
        setCheque_list_drop([]);
        setAddDataHead({});
        toast.success(res.message);
        setIsAdd(false);
        store.dispatch(get_cheque_list(obj));
      } else if (!res.success) {
        setOpen1(true);
        setMess(res.message);
      }
      try {
      } catch (error) {
        console.log("err   ", error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);
  return (
    <div className="container">
      {/*Page Heading  */}
      <PageHeading
        title={"CHEQUE CLEARANCE"}
        right={
          <>
            <Button
              title={"UPDATE STATUS"}
              onClick={() => {
                setIsAdd(true);
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />

      {/* List Data  */}

      {cheque_loading ? (
        <PageLoading />
      ) : cheque_list?.length == 0 ? (
        <NoData mt="2rem" />
      ) : (
        <>
          <Pagination2
            top={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {cheque_list.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">{v?.CUSTOMER_NAME}</div>
                  <RowTxt title={"Cheque No."} value={v?.CHEQUE_NO} />
                  <RowTxt
                    title={"Cheque Date"}
                    value={moment(Number(v?.CHEQUE_DATE)).format("DD-MMM-YYYY")}
                  />
                  <RowTxt
                    title={"Cheque Clear Date"}
                    value={moment(Number(v?.CLEARED_DATE)).format(
                      "DD-MMM-YYYY"
                    )}
                  />
                  <RowTxt
                    title={"Cheque Amt."}
                    value={v?.PYMT_AMOUNT || v?.RCP_AMOUNT}
                  />
                  <RowTxt title={"Bank Acc."} value={v?.BANK_ACC_NO} />
                  <RowTxt
                    title={"Status"}
                    value={
                      v?.CLEARANCE_STATUS == "1"
                        ? "Under Clearance"
                        : v?.CLEARANCE_STATUS == "2"
                        ? "Realised"
                        : v?.CLEARANCE_STATUS == "3"
                        ? "Returned"
                        : ""
                    }
                  />
                  <RowTxt title={"Recieved By"} value={v?.STAFF_NAME} />
                  <RowTxt title={"Narration"} value={v?.NARRATION} />
                </div>
                <div className="df jcsb mt10 " style={{justifyContent:"flex-end"}}>
                  {/* <DeleteIcon
                    onClick={() => {
                      setId(v.ID);
                      setOpen(true);
                    }}
                  /> */}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                    onClick={() => {
                      setOpenView(true);
                      setViewItem(v);
                    }}
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
            bottom={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      )}

      {/* Add Data Module  */}

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"CHEQUE CLEARANCE"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <DropInput
                  label={"CUSTOMER NAME"}
                  id="cust"
                  placeholder={"CUSTOMER NAME"}
                  value={addDataHead?.customer_name}
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Customer,
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                      ...transition_complete_list?.Others,
                    ]?.filter((item) => item?.ID == e.target.value);
                    setAddDataHead({
                      ...addDataHead,
                      customer_name:
                        data?.length > 0 ? data[0]?.cvo_name : e.target.value,
                      customer_id: data?.length == 0 ? 0 : e.target.value,
                    });
                    if (data?.length > 0) {
                      get_cheque_list_drop(data?.[0]?.ID);
                    } else {
                      setCheque_list_drop([]);
                    }
                  }}
                  list={[
                    ...transition_complete_list?.Customer,
                    ...transition_complete_list?.Vendor,
                    ...transition_complete_list?.OMC,
                    ...transition_complete_list?.Others,
                  ]?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.cvo_name?.toUpperCase()}
                    </option>
                  ))}
                />
                <Dropdown
                  width2=""
                  value={addDataHead?.cheque_no}
                  // disabled={true}
                  label={"CHEQUE NO."}
                  onChange={(e) => {
                    const data = cheque_list_drop?.filter(
                      (v) => v?.ID == e.target.value
                    );
                    console.log("data ===", data);
                    setAddDataHead({
                      ...addDataHead,
                      cheque_no: e.target.value,
                      payment_type:
                        data?.length > 0
                          ? data[0]?.PAYMENT_TYPE == "PAYMENT"
                            ? "2"
                            : data[0]?.PAYMENT_TYPE == "RECEIPT"
                            ? "1"
                            : ""
                          : "",
                      cheque_date:
                        data?.length > 0
                          ? moment(
                              Number(data[0]?.CLEARED_DATE || new Date())
                            ).format("YYYY-MM-DD")
                          : "",
                      bank_account: data
                        ? data[0]?.CREDITED_BANK || data[0]?.DEBITED_BANK
                        : "",
                      cheque_value:
                        data?.length > 0 ? data[0]?.CHECK_VALUE : "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {cheque_list_drop?.map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.CHEQUE_NO}
                        </option>
                      ))}
                    </>
                  }
                />

                <Input
                  label={"CHEQUE DATE"}
                  type={"date"}
                  value={addDataHead.cheque_date}
                  disabled={true}
                />
                <Input
                  label={"CHEQUE VALUE"}
                  placeholder={"CHEQUE VALUE"}
                  value={addDataHead.cheque_value}
                  disabled={true}
                />

                <Input
                  label={"CHEQUE CLEAR DATE"}
                  type={"date"}
                  value={addDataHead.cheque_deposite_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        cheque_deposite_date: e.target.value,
                      });
                    }
                  }}
                />

                <Dropdown
                  width2=""
                  value={addDataHead?.bank_account}
                  disabled={true}
                  label={"DEBITED/CREDITED TO"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      bank_account: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {bank_master_list
                        ?.filter(
                          (v) =>
                            // v?.BANK_CODE == "TAR ACCOUNT" ||
                            v?.BANK_CODE == "SAVINGS" ||
                            v?.BANK_CODE == "OVER DRAFT" ||
                            v?.BANK_CODE == "LOAN" ||
                            v?.BANK_CODE == "CURRENT"
                        )
                        ?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {bankCode(v?.BANK_CODE)} -
                            {v?.BANK_ACC_NO == "NA" ? user_id : v?.BANK_ACC_NO}
                          </option>
                        ))}
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  value={addDataHead?.status}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      status: e.target.value,
                    });
                  }}
                  label={"STATUS"}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {/* <option value={"1"}>Under Clearance</option> */}
                      <option value={"2"}>Realised</option>
                      <option value={"3"}>Returned</option>
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  value={addDataHead?.payment_type}
                  disabled={true}
                  label={"PAYMENT TYPE"}
                  options={
                    <>
                      <option value={""}>Type Of payment</option>
                      <option value={"1"}>Inward(Receipts)</option>
                      <option value={"2"}>Outward(Payment)</option>
                    </>
                  }
                />
                <Input
                  value={addDataHead?.narration}
                  placeholder={"NARRATION"}
                  label={"NARRATION"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      narration: e.target.value,
                    });
                  }}
                />
              </div>

              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <SaveButton back onClick={add_items} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {/* View Modal */}

      {openView && (
        <Modal
          isOpen={openView}
          onCancel={() => {
            setViewItem({});
          }}
          title={viewItem?.CUSTOMER_NAME}
          setIsOpen={setOpenView}
          body={
            <Table
              mt={10}
              headData={[
                "CHEQUE NO.",
                "CHEQUE DATE",
                "CHEQUE VALUE",
                "CHEQUE CLEAR DATE",
                "DEBITED/CREDITED TO",
                "STATUS",
                "PAYMENT TYPE",
                "NARRATION",
              ]}
              body={
                <tr>
                  <td>{viewItem?.CHEQUE_NO}</td>
                  <td>
                    {moment(Number(viewItem?.CHEQUE_DATE)).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>{viewItem?.RCP_AMOUNT}</td>
                  <td>
                    {moment(Number(viewItem?.CLEARED_DATE)).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>{viewItem?.BANK_ACC_NO}</td>
                  <td>
                    {viewItem?.CLEARANCE_STATUS == "1"
                      ? "Under Clearance"
                      : viewItem?.CLEARANCE_STATUS == "2"
                      ? "Realised"
                      : viewItem?.CLEARANCE_STATUS == "3"
                      ? "Returned"
                      : ""}
                  </td>

                  <td>{viewItem?.PAYMENT_TYPE_NAME}</td>
                  <td>{viewItem?.NARRATION}</td>
                </tr>
              }
            />
          }
        />
      )}

      {/* Delete Modal */}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {/* Genral Alerts */}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {/* Loader Main */}

      {loading && <Loader />}
    </div>
  );
};

export default Cheque;
