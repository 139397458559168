import React from "react";
import PageHeading from "../../components/PageHeading";
import {  BackButton, HelpButton } from "../../components/btn/Buttons";
import Button from "../../components/btn/Button";
import Input from "../../components/inputs/Input";
import "./DayEnd.css";
import Table from "../../components/table/Table";
const DayEnd = () => {



  return (
    <div className="container">
      <PageHeading
        title={"DAY END REPORT"}
        right={
          <>
            <BackButton />
            <HelpButton
            link={[
              "https://youtu.be/oEzSuwSIkRg",
              "https://youtu.be/rrX-pqA7h68",
            ]}
            />
          </>
        }
      />
      <div style={{ height: "20px" }}></div>
      <div>
        <p className="DayEndText">
          LAST DAY END REPORT SUBMITTED DATE : 31-Oct-2020
        </p>
      </div>
      <div className="transactionMain">
        {" "}
        <div style={{ width: "180px" }}>
          <Input placeholder={"10/10/2023"} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div>
            <button className="GENERATE-BTN">GENERATE</button>
          </div>
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <p className="DayEndText"></p>
      <div style={{ height: "10px" }}></div>

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <Button title={"SUBMIT"} />
      </div>
      <div>
        <p className="DayEndText mt10">CYLINDER STOCK DETAILS</p>

        <Table
          mt={5}
          headData={[
            "CUSTOMER NAME",
            "< 30 DAYS DATE",
            "31-60 DAYS",
            "C> 60 DAYS",
            "BALANCE",
            "BALANCE",
            "BALANCE",
            "BALANCE",
          ]}
          body={
            <>
              <tr>
                <td>cust yes</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
              </tr>
            </>
          }
        />
      </div>
      <div>
        <p className="DayEndText mt10">ARB STOCK DETAILS</p>

        <Table
          mt={5}
          headData={[
            "CUSTOMER NAME",
            "< 30 DAYS DATE",
            "31-60 DAYS",
            "C> 60 DAYS",
            "BALANCE",
            "BALANCE",
            "BALANCE",
            "BALANCE",
            "BALANCE",
          ]}
          body={
            <>
              <tr>
                <td>cust yes</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
              </tr>
            </>
          }
        />
      </div>
      <div>
        <p className="DayEndText mt10">BANK TRANSACTIONS</p>

        <Table
          mt={5}
          headData={[
            "CUSTOMER NAME",
            "< 30 DAYS DATE",
            "31-60 DAYS",
            "C> 60 DAYS",
            "BALANCE",
            "BALANCE",
          ]}
          body={
            <>
              <tr>
                <td>cust yes</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
              </tr>
            </>
          }
        />
      </div>
      <div>
        <p className="DayEndText mt10">CASH TRANSACTIONS</p>

      </div>
    </div>
  );
};

export default DayEnd;
