import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../../components/Loader";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { IoArrowBack } from "react-icons/io5";
import PageHeading from "../../../components/PageHeading";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../components/inputs/Dropdown";
import { useSelector } from "react-redux";
import {
  get_arb_list,
  get_bank_master_list,
  get_customer_vender_list,
  get_master_complete_list,
  get_nc_dbc_list,
  get_service_master_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import store from "../../../redux/store";
import { Pagination2 } from "../../../components/pagination/pagination";
import RowTxt from "../../../components/RowTxt";
import { DeleteIcon, Text, Radio, BankCodeFinder } from "../../../utils/utils";
import { HiOutlineArrowRight } from "react-icons/hi";
import PageLoading from "../../../components/PageLoading";
import NoData from "../../../components/NoData";
import { motion } from "framer-motion";
import SmallModal from "../../../components/modal/SmallModal";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Table from "../../../components/table/Table";
import moment from "moment";
import {
  get_ncdbc_data_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { DropInput } from "../../../components/inputs/DropInput";
import { nc_dbc_data_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import { DateValidator2, gst_calculate } from "../../../utils/validation";
import { Alerts } from "../../../components/Alerts/Alerts";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { FaTruckDroplet } from "react-icons/fa6";
import { UOMData } from "../../../utils/data";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
// const tax_invoice_table_header = [
//   "S No.",
//   "PRODUCT",
//   "HSN/SAC",
//   "QTY",
//   "UOM",
//   "RATE",
//   "DISCOUNT",
//   "SECURITY DEPOSIT",
//   "TAXABLE",
//   "GST %	",
//   "TOTAL",
// ];
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

export const NCDBCTran = () => {
  const previewRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [addData, setAddData] = useState({});
  const [ncId, setNcId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const { user_id, today } = useSelector((state) => state.other);

  console.log("viewItem ===", viewItem);

  const { godown_address_list } = useSelector((state) => state.profile);

  const {
    nc_dbc_list,
    staff_list,
    bank_master_list,
    arb_list,
    service_master_list,
    master_complete_list,
  } = useSelector((state) => state.masterdata);
  const { day_end_date } = useSelector((state) => state.profile);
  const { transition_complete_list, nc_dbc_data_list, nc_dbc_data_loading } =
    useSelector((state) => state.transactions);
  const navigate = useNavigate();
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
  };
  useEffect(() => {
    store.dispatch(get_nc_dbc_list());
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_service_master_list());
    store.dispatch(get_master_complete_list());
  }, []);
  useEffect(() => {
    store.dispatch(get_ncdbc_data_list(obj));
  }, [pageNo, entries]);

  const handleView = (item) => {
    setViewItem(item);
    setOpenViewModal(true);
  };

  const validate_new = (type) => {
    const data = [];
    const data1 = DateValidator2(
      moment(addData?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice Date Cant be below as a day end date ${day_end_date}`,
      `Invoice Date Cant be be a future date`
    );
    if (
      !addData?.inv_date ||
      (addData?.inv_date && addData?.inv_date?.length == 0)
    ) {
      data.push("Please enter INVOICE DATE");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      type == "invoicenumber" &&
      (!addData?.customer_name ||
        (addData?.customer_name && addData?.customer_name?.length == 0))
    ) {
      data.push("Please Enter CUSTOMER NO/NAME and then fetch data");
    }
    return data;
  };
  const fetch_deposit = async () => {
    const data = validate_new("fetchgst");
    if (addData?.items?.length > 0) {
      if (data?.length == 0) {
        console.log(addData?.items);
        try {
          setLoading(true);
          const body = new FormData();
          body.append("action", "fetchgst");
          body.append("agencyid", user_id);
          body.append("invoice_date", addData?.inv_date);
          addData?.items?.forEach((v) =>
            body.append("PROD_CODE[]", v?.prod_code)
          );
          addData?.items?.forEach((v) =>
            body.append(
              "type[]",
              // "1"
              v?.prod_name == "DOMESTIC" ||
                v?.prod_name == "REGULATOR" ||
                v?.prod_name == "COMMERCIAL"
                ? "1"
                : v?.prod_name == "ARB"
                ? "2"
                : v?.prod_name == "SERVICE"
                ? "3"
                : "3"
            )
          );
          const response = await nc_dbc_data_API(body);
          setLoading(false);
          if (response.success) {
            const data = response?.results?.filter(
              (v) => v?.BASE_PRICE == null
            );
            if (data?.length > 0) {
              setMess(
                "please define the price of product for sale invoice month in price master and continue"
              );
              setOpen(true);
            } else if (data?.length == 0) {
              const updatedItems = addData.items.map((item) => {
                const data = response?.results?.filter(
                  (v) =>
                    v?.PROD_CODE == item?.prod_code || v?.ID == item?.prod_code
                )?.[0];

                return {
                  ...item,
                  gstp: data?.GSTP == "NA" ? 0 : data?.GSTP,
                  unit_rate: data?.BASE_PRICE,
                  deposit: data?.SECURITY_DEPOSIT,
                };
              });

              setAddData({
                ...addData,
                items: updatedItems,
              });
            }
          } else if (!response.success) {
            setMess(response.message);
            setOpen(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setMess(<Alerts data={data} />);
        setOpen(true);
      }
    } else {
      setMess("Please Add Product First ");
      setOpen(true);
    }
  };

  const fetch_invoice = async () => {
    const data = validate_new("invoicenumber");
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addData?.inv_date);
        body.append("customer", addData?.customer_id);
        const response = await nc_dbc_data_API(body);
        setLoading(false);
        if (response.success) {
          setAddData({
            ...addData,
            inv_no: response?.invoice_number,
            inv_dis: true,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = () => {
    const arr = [];
    if (
      !addData?.inv_date ||
      (addData?.inv_date && addData?.inv_date?.length == 0)
    ) {
      arr.push("Please Enter Invoice Date");
    } else if (
      !addData?.inv_no ||
      (addData?.inv_no && addData?.inv_no?.length == 0)
    ) {
      arr.push("Please Enter Invoice Number");
    }
    if (
      !addData?.staff_name ||
      (addData?.staff_name && addData?.staff_name?.length == 0)
    ) {
      arr.push("Please Select Staff Name");
    }
    if (
      !addData?.customer_name ||
      (addData?.customer_name && addData?.customer_name?.length == 0)
    ) {
      arr.push("Please Select Customer Name");
    }
    if (
      !addData?.conn_no ||
      (addData?.conn_no && addData?.conn_no?.length == 0)
    ) {
      arr.push("Please Enter No. of Connection ");
    } else if (addData?.conn_no == 0) {
      arr.push("No. of Connection cant be Zero");
    }
    if (
      !addData?.cash_received ||
      (addData?.cash_received && addData?.cash_received?.length == 0)
    ) {
      arr.push("Please enter CASH RECEIVED");
    } else if (
      !addData?.online_rcvd ||
      (addData?.online_rcvd && addData?.online_rcvd?.length == 0)
    ) {
      arr.push("Please enter AMOUNT RECEIVED ONLINE");
    } else if (addData?.cash_received < 0 || addData?.online_rcvd < 0) {
      if (addData?.cash_received < 0) {
        arr.push("Cash Received amount cant be lessthen Zero");
      } else if (addData?.online_rcvd < 0) {
        arr.push("Online Received amount cant be lessthen Zero");
      }
    } else if (
      addData?.online_rcvd > 0 &&
      (!addData?.bank_acc ||
        (addData?.bank_acc && addData?.bank_acc?.length == 0))
    ) {
      arr.push("Please Select Bank Account Number");
    }
    if (
      addData?.items?.filter(
        (v) =>
          v?.prod_name == "COMMERCIAL" ||
          v?.prod_name == "DOMESTIC" ||
          v?.prod_name == "REGULATOR"
      )?.length > 0 &&
      godown_address_list?.length > 1 &&
      (!addData?.godown || (addData?.godown && addData?.godown?.length === 0))
    ) {
      arr.push("Please Select Dispatch Godown Address");
    }
    if (
      !addData?.inv_amount ||
      (addData?.inv_amount && addData?.inv_amount <= 0)
    ) {
      arr.push("Please Click on Calculate First");
    }

    const data1 = validate_add();

    if (data1?.length > 0) {
      arr.push(...data1);
    }
    return arr;
  };

  const validate_add = () => {
    const arr = [];
    addData?.items?.find((v) => {
      if (!v?.prod_code || (v?.prod_code && v?.prod_code?.length == 0)) {
        arr.push("Please Select Product First ");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length == 0)) {
        arr.push("Please Click on Fecth Deposite Button first");
      }
      if (!v?.discount || (v?.discount && v?.discount?.length == 0)) {
        arr.push("Please Enter Discount");
      }
      if (
        !v?.tax_amount ||
        !v?.cgst ||
        !v?.sgst ||
        !v?.amount ||
        (v?.tax_amount && v?.tax_amount?.length == 0) ||
        (v?.cgst && v?.cgst?.length == 0) ||
        (v?.sgst && v?.sgst?.length == 0) ||
        (v?.amount && v?.amount?.length == 0)
      ) {
        arr.push("Please Click on Calculate first");
      }
    });
    return arr;
  };

  const add_items = async () => {
    const errorData = validate();
    if (addData?.items?.length > 0) {
      if (errorData?.length == 0) {
        if (addData?.inv_amount > 250000) {
          setMess(
            "YOUR INVOICE AMOUNT EXCEEDS RS.2,50,000/- .IF YOU WANT TO ADD FURTHER,ADD IN NEXT INVOICE"
          );
          setOpen(true);
          return;
        }
        try {
          setLoading(true);
          const body = new FormData();
          body.append("action", "create");
          body.append("agencyid", user_id);
          body.append("SR_NO", addData?.inv_no);
          body.append("BOM_ID", addData?.id);
          body.append(
            "CONN_TYPE",
            addData?.bom_type == "GEN NC"
              ? "1"
              : addData?.bom_type == "LOAN"
              ? "3"
              : addData?.bom_type == "CASH"
              ? "5"
              : addData?.bom_type == "DBC"
              ? "2"
              : addData?.bom_type == "BPL"
              ? "4"
              : ""
          );
          body.append("INV_DATE", addData?.inv_date);
          body.append("CUSTOMER_NO", addData?.customer_name);
          body.append("CUSTOMER_ID", addData?.customer_id);
          body.append("STAFF_ID", addData?.staff_name);
          body.append("NO_OF_CONNS", addData?.conn_no);
          body.append("CASH_AMOUNT", addData?.cash_received);
          body.append("ONLINE_AMOUNT", addData?.online_rcvd);
          body.append("DEP_AMOUNT", addData?.inv_no);
          body.append("INV_AMOUNT", addData?.inv_amount);
          body.append("BANK_ID", addData?.bank_acc);
          addData?.items?.forEach((v) => {
            body.append("PROD_CODE[]", v?.prod_code);
            body.append("QUANTITY[]", v?.qty);
            body.append("UNIT_RATE[]", v?.unit_rate);
            body.append("DISC_UNIT_RATE[]", v?.discount);
            body.append("BASIC_PRICE[]", v?.tax_amount);
            body.append("DEPOSIT_AMOUNT[]", v?.deposit);
            body.append("GSTP[]", v?.gstp);
            body.append("SGST_AMOUNT[]", v?.sgst);
            body.append("CGST_AMOUNT[]", v?.cgst);
            body.append("PRODUCT_AMOUNT[]", v?.amount);
          });
          if (
            addData?.items?.filter(
              (v) =>
                v?.prod_name == "COMMERCIAL" ||
                v?.prod_name == "DOMESTIC" ||
                v?.prod_name == "REGULATOR"
            )?.length > 0
          ) {
            body.append(
              "godown_id",
              addData?.godown || godown_address_list?.[0]?.ID || 0
            );
          }
          const response = await nc_dbc_data_API(body);
          setLoading(false);
          if (response.success) {
            store.dispatch(get_ncdbc_data_list(obj));
            setIsAdd(false);
            setAddData({});
            setNcId("");
            toast.success(response.message);
          } else if (!response.success) {
            setMess(response.message);
            setOpen(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setMess(<Alerts data={errorData} />);
        setOpen(true);
      }
    } else {
      setMess("Please Add Product First ");
      setOpen(true);
    }
  };
  const onDelete_item = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      const response = await nc_dbc_data_API(body);
      setLoading(false);
      if (response?.success) {
        setPageNo(nc_dbc_data_list?.length < 2 ? pageNo - 1 : pageNo);
        store.dispatch(
          get_ncdbc_data_list({
            page: nc_dbc_data_list?.length < 2 ? pageNo - 1 : pageNo,
            limit: entries,
            setTotalCount: setTotalCount,
          })
        );
        toast(response?.message);
        setOpen1(false);
      } else {
        setMess(response?.message);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate_calculate = () => {
    const data = [];
    if (
      !addData?.inv_date ||
      (addData?.inv_date && addData?.inv_date?.length != 10)
    ) {
      data.push("Please select Invoice Date");
    }

    if (
      !addData?.customer_name ||
      (addData?.customer_name && addData?.customer_name?.length == 0)
    ) {
      data.push("Please select Customer");
    }
    if (
      !addData?.conn_no ||
      (addData?.conn_no && addData?.conn_no?.length == 0)
    ) {
      data.push("Please Enter No. of Connection");
    } else if (addData?.conn_no == 0) {
      data.push("No. of Connection cant be Zero");
    }

    if (
      addData?.items.some((v) => !v?.qty || (v?.qty && v?.qty.length === 0))
    ) {
      data.push(`Please Enter Quantity`);
    }
    if (
      addData?.items.some(
        (v) => !v?.discount || (v?.discount && v?.discount.length === 0)
      )
    ) {
      data.push(`Please Enter DISCOUNT`);
    } else if (
      addData?.items.some((v) => Number(v?.unit_rate) <= Number(v?.discount))
    ) {
      data.push(`DISCOUNT cant be greaterthen or equal to unit rate`);
    }
    return data;
  };

  const calculate = () => {
    const data = validate_calculate();
    // const
    if (addData?.items?.length > 0) {
      if (data?.length == 0) {
        var total = 0;
        var deposite = 0;
        let updatedItems = addData.items.map((data) => {
          const unit_rate = Number(data?.unit_rate);
          const discount = Number(data?.discount);
          const quantity = Number(data?.qty);
          const p_gst = Number(data?.gstp);
          const deposit_amt = Number(data?.deposit);

          const tax_amount =
            (unit_rate - discount) * quantity * Number(addData?.conn_no);
          console.log("tax_amount ===", tax_amount);
          const gst_amount = tax_amount * (p_gst / 100);
          const amount = tax_amount + gst_amount;
          total += amount;
          deposite += deposit_amt * Number(addData?.conn_no);
          return {
            ...data,
            tax_amount: tax_amount.toFixed(2),
            cgst: (gst_amount / 2).toFixed(2),
            sgst: (gst_amount / 2).toFixed(2),
            amount: amount.toFixed(2),
          };
        });
        console.log((Number(total) + Number(deposite)).toFixed(2));
        setAddData({
          ...addData,
          items: updatedItems,
          deposite: deposite,
          inv_amount: (Number(total) + Number(deposite)).toFixed(2),
          total_amount: total.toFixed(2),
          cash_received:
            addData?.cash_received && addData?.cash_received != 0
              ? addData?.cash_received
              : (Number(total) + Number(deposite)).toFixed(2),
          online_rcvd:
            addData?.cash_received && addData?.cash_received != 0
              ? (
                  Number(total) +
                  Number(deposite) -
                  Number(addData?.cash_received || 0)
                ).toFixed(2)
              : "0.00",
        });
      } else {
        setMess(<Alerts data={data} />);
        setOpen(true);
      }
    } else {
      setMess("Please Add Product First ");
      setOpen(true);
    }
  };
  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);
  return (
    <div className="container">
      <PageHeading
        title={"NC / DBC DATA"}
        right={
          <>
            <Dropdown
              width2={"150px"}
              onChange={(e) => setNcId(e.target.value)}
              value={ncId}
              options={
                <>
                  <option value="">SELECT</option>
                  {nc_dbc_list?.map((v, i) => (
                    <option value={v?.id} key={i}>
                      {v?.Bom_name}
                    </option>
                  ))}
                </>
              }
            />
            <Button
              title={"ADD NC / DBC"}
              onClick={() => {
                if (!ncId || ncId?.length == 0 || ncId == "") {
                  setOpen(true);
                  setMess("Please Select BOM");
                } else {
                  const fiterData = nc_dbc_list?.filter((v) => v?.id == ncId);
                  if (fiterData) {
                    setAddData(fiterData[0]);
                    setIsAdd(true);
                  }
                }
              }}
            />
            <BackButton />
            <Button
              title={"ADD NC / PACKAGING"}
              bgColor={"#0797ad"}
              onClick={() => navigate("/MasterData/NC/DBC-Packaging")}
            />
            <HelpButton
              link={[
                "https://youtu.be/_vkck33H-zE",
                "https://youtu.be/0iV0CiU67xc",
              ]}
            />
          </>
        }
      />

      {nc_dbc_data_loading ? (
        <PageLoading />
      ) : nc_dbc_data_list?.length == 0 ? (
        <NoData />
      ) : (
        <>
          <Pagination2
            top={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {nc_dbc_data_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v.CUSTOMER_NAME || v?.CUSTOMER_NO}{" "}
                  </div>
                  <RowTxt title={"Connection Type"} value={v?.CONN_NAME} />
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.CUSTOMER_NAME}-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.INV_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Invoice Amt."} value={v?.INV_AMOUNT} />
                  <RowTxt title={"Godown Address "} value={v?.GODOWN_NAME} />
                  <RowTxt title={"Staff "} value={v?.STAFF_NAME} />
                  <RowTxt title={"No. of Connections"} value={v?.NO_OF_CONNS} />
                  <RowTxt title={"Cash Recieved"} value={v?.CASH_AMOUNT} />
                  <RowTxt title={"Online Recieved"} value={v?.ONLINE_AMOUNT} />
                  {/* <RowTxt title={"Godown"} value={v?.GODOWN_NAME} /> */}
                  {v?.ONLINE_AMOUNT == "0.00" ? (
                    ""
                  ) : (
                    <RowTxt title={"Bank Account"} value={v?.BANK_ACC_NO} />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <DeleteIcon
                    onClick={() => {
                      setOpen1(true);
                      setDeleteId(v?.ID);
                    }}
                  />
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    onClick={() => handleView(v)}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
            bottom={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      )}

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData({});
            setNcId("");
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"NC / DBC DATA"}
          body={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div
                  className="flex aic gap10"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="df aic ">
                    <div>
                      <Text color={"#6f6f6f"} fs={"13px"} fw={500} flex={1}>
                        CONNECTION TYPE :
                      </Text>
                    </div>
                    <Radio
                      show={addData?.bom_type == "GEN NC"}
                      title={"GEN NC"}
                      disabled={true}
                    />
                    <Radio
                      show={addData?.bom_type == "LOAN"}
                      title={"UJJWALA LOAN NC"}
                      disabled={true}
                    />
                    <Radio
                      show={addData?.bom_type == "CASH"}
                      title={"UJJWALA CASH"}
                      disabled={true}
                    />
                    <Radio
                      show={addData?.bom_type == "DBC"}
                      title={"DBC"}
                      disabled={true}
                    />
                    <Radio
                      show={addData?.bom_type == "BPL"}
                      title={"BPL"}
                      disabled={true}
                    />
                  </div>
                  <div className="df aic " style={{ paddingRight: "3rem" }}>
                    <Input
                      label={"INVOICE AMOUNT"}
                      placeholder={"INVOICE AMOUNT"}
                      value={addData?.inv_amount}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex aic gap10 credit-limit l24">
                  <Input
                    label={"INVOICE DATE"}
                    value={addData?.inv_date}
                    placeholder={"INVOICE DATE"}
                    type={"date"}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddData({ ...addData, inv_date: e.target.value });
                      }
                    }}
                  />
                  <Input
                    label={"INVOICE NUMBER"}
                    placeholder={"INVOICE NUMBER"}
                    value={addData?.inv_no}
                    disabled={true}
                  />

                  <Dropdown
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddData({ ...addData, staff_name: e.target.value });
                      }
                    }}
                    width2=""
                    label={"STAFF NAME"}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        {staff_list?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.EMP_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <DropInput
                    label={"CUST NO / CUST NAME"}
                    id="custvend"
                    placeholder={"CUST NO / CUST NAME"}
                    value={addData?.customer_name}
                    onChange={(e) => {
                      const data = transition_complete_list?.Customer?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setAddData({
                        ...addData,
                        customer_name:
                          data?.length > 0 ? data[0]?.cvo_name : e.target.value,
                        customer_id: data?.length > 0 ? e.target.value : "",
                      });
                    }}
                    list={transition_complete_list?.Customer?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                  />
                  <Input
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input.length < 2) {
                        const updatedItems = addData.items.map((item) => {
                          return {
                            ...item,
                            tax_amount: "",
                            cgst: "",
                            sgst: "",
                            amount: "",
                          };
                        });
                        setAddData({
                          ...addData,
                          conn_no: input.replace(/[^0-9]/g, ""),
                          items: updatedItems,
                        });
                      }
                    }}
                    value={addData?.conn_no}
                    label={"NO. OF CONNECTIONS"}
                    placeholder={"NO. OF CONNECTIONS"}
                  />
                  <Input
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length <= 10 &&
                          /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                            inputValue
                          )) ||
                        inputValue === "" ||
                        inputValue === "-" ||
                        !addData?.cash_received
                      ) {
                        setAddData({
                          ...addData,
                          cash_received:
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            ) || inputValue === ""
                              ? inputValue
                              : "",
                          online_rcvd:
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            ) ||
                            inputValue === "" ||
                            inputValue === "-"
                              ? Number(
                                  Number(addData?.inv_amount || 0) -
                                    e.target.value
                                )?.toFixed(2)
                              : "",
                        });
                      }
                    }}
                    value={addData?.cash_received}
                    label={"CASH RECEIVED"}
                    placeholder={"CASH RECEIVED"}
                  />
                  <Input
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length <= 10 &&
                          /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                            inputValue
                          )) ||
                        inputValue === "" ||
                        inputValue === "-" ||
                        !addData?.online_rcvd
                      ) {
                        setAddData({
                          ...addData,
                          online_rcvd:
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            ) || inputValue === ""
                              ? inputValue
                              : "",
                          cash_received:
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            ) ||
                            inputValue === "" ||
                            inputValue === "-"
                              ? Number(
                                  Number(addData?.inv_amount || 0) -
                                    e.target.value
                                )?.toFixed(2)
                              : "",
                        });
                      }
                    }}
                    value={addData?.online_rcvd}
                    label={"AMOUNT RECEIVED ONLINE"}
                    placeholder={"AMOUNT RECEIVED ONLINE"}
                  />
                  <Dropdown
                    disabled={Number(addData?.online_rcvd || 0) <= 0}
                    width2=""
                    label={"BANK ACCOUNT"}
                    onChange={(e) => {
                      setAddData({ ...addData, bank_acc: e.target.value });
                    }}
                    value={addData?.bank_acc}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        {bank_master_list
                          ?.filter(
                            (v) =>
                              v?.BANK_CODE == "TAR ACCOUNT" ||
                              v?.BANK_CODE == "SAVINGS" ||
                              v?.BANK_CODE == "CURRENT" ||
                              v?.BANK_CODE == "OVER DRAFT"
                          )
                          ?.map((v, i) => (
                            <option key={i} value={v?.ID}>
                              {bankCode(v?.BANK_CODE)} -
                              {v?.BANK_ACC_NO == "NA"
                                ? user_id
                                : v?.BANK_ACC_NO}
                            </option>
                          ))}
                      </>
                    }
                  />
                  {godown_address_list?.length > 1 &&
                    addData?.items?.filter(
                      (v) =>
                        v?.prod_name == "COMMERCIAL" ||
                        v?.prod_name == "DOMESTIC" ||
                        v?.prod_name == "REGULATOR"
                    )?.length > 0 && (
                      <Dropdown
                        label={"Dispatch Godown"}
                        value={addData?.godown}
                        onChange={(e) => {
                          setAddData({ ...addData, godown: e.target.value });
                        }}
                        options={
                          <>
                            <option value={""}>SELECT</option>
                            {godown_address_list?.map((s, i) => (
                              <option key={i} value={s?.ID}>
                                {s?.UNIT_NAME}
                              </option>
                            ))}
                          </>
                        }
                      />
                    )}
                </div>
                <div
                  style={{
                    maxHeight: "20%",
                    overflow: "scroll",
                    display: "flex",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Table
                    headData={[
                      "PRODUCT",
                      "QUANTITY",
                      "UNIT PRICE",
                      "GST %",
                      "DEPOSIT",
                      "DISCOUNT ON UNIT PRICE",
                      "TAXABLE VALUE",
                      "CGST AMOUNT",
                      "SGST AMOUNT",
                      "PRODUCT AMOUNT",
                      "ACTIONS",
                    ]}
                    body={addData?.items?.map((val, ind) => (
                      <tr key={ind}>
                        <td>
                          <Dropdown
                            options={
                              <>
                                <option value="">SELECT</option>
                                {master_complete_list?.equipment?.map(
                                  (v, i) => (
                                    <option key={i} value={v.ID}>
                                      {v.CAT_NAME + " - " + v.CAT_DESC}
                                    </option>
                                  )
                                )}

                                {arb_list.map((arb, i) => (
                                  <option key={i} value={arb?.ID}>
                                    {arb?.category_description}-
                                    {arb?.PROD_BRAND} -{arb?.PROD_NAME}
                                  </option>
                                ))}
                                {service_master_list?.map((v, i) => (
                                  <option value={v.PROD_CODE || v?.ID} key={i}>
                                    {v.CAT_DESC}
                                  </option>
                                ))}
                              </>
                            }
                            value={val?.prod_code}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const data = [
                                ...master_complete_list?.equipment,
                                ...arb_list,
                                ...service_master_list,
                              ]?.filter(
                                (v) =>
                                  v?.ID == e.target.value ||
                                  v?.PROD_CODE == e.target.value
                              );
                              const updatedItems = addData?.items?.map(
                                (item, i) =>
                                  i == ind
                                    ? {
                                        ...item,
                                        prod_code: /^\d+$/.test(inputValue)
                                          ? Number(inputValue)
                                          : "",
                                        tax_amount: "",
                                        cgst: "",
                                        sgst: "",
                                        amount: "",
                                        qty: "",
                                        unit_rate: "",
                                        gstp: "",
                                        default: false,
                                        prod_name:
                                          data?.[0]?.CAT_NAME == "DOMESTIC" ||
                                          data?.[0]?.CAT_NAME == "REGULATOR" ||
                                          data?.[0]?.CAT_NAME == "COMMERCIAL"
                                            ? data?.[0]?.CAT_NAME
                                            : data?.[0]?.default
                                            ? "SERVICE"
                                            : data?.length > 0
                                            ? "ARB"
                                            : "",
                                      }
                                    : item
                              );
                              setAddData((prevData) => ({
                                ...prevData,
                                items: updatedItems,
                              }));
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"Quantity"}
                            disabled={val?.default == false ? false : true}
                            value={val?.qty}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              if (numericValue?.length < 5) {
                                const updatedItems = addData?.items?.map(
                                  (item, i) =>
                                    i == ind
                                      ? {
                                          ...item,
                                          qty: /^\d+$/.test(numericValue)
                                            ? Number(numericValue)
                                            : "",
                                          tax_amount: "",
                                          cgst: "",
                                          sgst: "",
                                          amount: "",
                                        }
                                      : item
                                );
                                setAddData((prevData) => ({
                                  ...prevData,
                                  items: updatedItems,
                                }));
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            value={val?.unit_rate}
                            placeholder={"UNIT PRICE"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            disabled={true}
                            placeholder={"GST %"}
                            value={val?.gstp}
                          />
                        </td>
                        <td>
                          <Input
                            disabled={true}
                            placeholder={"DEPOSIT"}
                            value={val?.deposit}
                          />
                        </td>

                        <td>
                          <Input
                            value={val?.discount}
                            placeholder={"DISCOUNT ON UNIT PRICE"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 8 &&
                                  /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !val?.discount
                              ) {
                                const updatedItems = addData?.items?.map(
                                  (item, i) =>
                                    i == ind
                                      ? {
                                          ...item,
                                          discount:
                                            /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tax_amount: "",
                                          cgst: "",
                                          sgst: "",
                                          amount: "",
                                        }
                                      : item
                                );
                                setAddData((prevData) => ({
                                  ...prevData,
                                  items: updatedItems,
                                }));
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            value={val?.tax_amount}
                            placeholder={"TAXABLE VALUE"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            value={val?.cgst}
                            placeholder={"CGST AMOUNT"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            value={val?.sgst}
                            placeholder={"SGST AMOUNT"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            value={val?.amount}
                            placeholder={"PRODUCT AMOUNT"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <DeleteIcon
                            onClick={() => {
                              const filterData = addData?.items?.filter(
                                (v, i) => i != ind
                              );
                              setAddData({
                                ...addData,
                                items: filterData,
                                inv_amount: "",
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                </div>
                <div
                  className="flex aic gap10"
                  style={{ marginTop: 10, justifyContent: "flex-end" }}
                >
                  <AddButton
                    onClick={() => {
                      const data1 = validate_add();

                      if (data1?.length == 0) {
                        const updatedItems = [
                          ...addData?.items,
                          { default: false },
                        ];
                        setAddData((prevData) => ({
                          ...prevData,
                          items: updatedItems,
                        }));
                      } else {
                        setMess(<Alerts data={data1} />);
                        setOpen(true);
                      }
                    }}
                  />
                  <Button
                    title={"FETCH INVOICE NO"}
                    disabled={addData?.inv_dis ? true : false}
                    onClick={fetch_invoice}
                    back
                  />
                  <Button
                    title={"FETCH DEPOSITS AND CHARGES"}
                    disabled={addData?.items?.length > 0 ? true : false}
                    onClick={fetch_deposit}
                    back
                  />
                  <Button
                    disabled={addData?.items?.length > 0 ? true : false}
                    title={"CALCULATE"}
                    onClick={() => calculate()}
                    back
                  />
                  <SaveButton
                    disabled={addData?.items?.length > 0 ? true : false}
                    onClick={add_items}
                  />
                </div>
              </div>
            </>
          }
        />
      )}

      {openViewModal && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          isOpen={openViewModal}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CUSTOMER_NAME}-${viewItem?.SR_NO}`}
            />
          }
          title={"NC / DBC DATA"}
          setIsOpen={setOpenViewModal}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "QUANTITY",
                  "UNIT PRICE",
                  "GST %",
                  "DEPOSIT",
                  "DISCOUNT ON UNIT RATE",
                  "TAXABLE VALUE",
                  "CGST AMOUNT",
                  "SGST AMOUNT",
                  "PRODUCT AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.details?.map((v, i) => (
                      <tr key={i}>
                        <td>
                          {v?.CAT_NAME} - {v?.CAT_DESC}
                        </td>
                        <td>{v?.QUANTITY}</td>
                        <td>{v?.UNIT_RATE}</td>
                        <td>{v?.GSTP}</td>
                        <td>{v?.DEPOSIT_AMOUNT}</td>
                        <td>{v?.DISC_UNIT_RATE}</td>
                        <td>{v?.BASIC_PRICE || 0}</td>
                        <td>{v?.CGST_AMOUNT || 0}</td>
                        <td>{v?.SGST_AMOUNT || 0}</td>
                        <td>{v?.PRODUCT_AMOUNT}</td>
                      </tr>
                    ))}

                    <tr>
                      <td
                        colSpan={6}
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        TOTAL
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.details
                          .map((v) => v?.BASIC_PRICE)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.details
                          .map((v) => v?.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.details
                          .map((v) => v?.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.details
                          .map((v) => v?.PRODUCT_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}
      <div style={{ display: "NONE" }}>
        <TaxInvoice2
          previewRef={previewRef}
          terms={false}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.INV_DATE,
            CVO_NAME: viewItem?.CUSTOMER_NAME || viewItem?.CUSTOMER_NO,
            CVO_ADDRESS: viewItem?.CUST_ADDR,
            // B_STATE: "Not Getting in the list",
            B_STATE_CODE: viewItem?.BILLING_STATE_CODE,
            B_STATE_NAME: viewItem?.BILLING_STATE_NAME,
            CVO_TIN: viewItem?.CVO_TIN || "NA",
            SHIP_CVO_NAME: viewItem?.CUSTOMER_NAME || viewItem?.CUSTOMER_NO,
            SHIP_CVO_ADDRESS: viewItem?.CUST_ADDR,
            // SHIP_B_STATE: "Not Getting in the list", // Currently not getting in the APIs
            SHIP_CVO_TIN: viewItem?.CVO_TIN || "NA",
            SHIP_B_STATE_NAME: viewItem?.BILLING_STATE_NAME,
            // SHIP_CVO_TIN: viewItem?.CVO_TIN || "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            EMP_NAME: viewItem?.STAFF_NAME,
          }}
          table_header={tax_invoice_table_header}
          // security_deposite={viewItem?.details
          //   ?.map((v) => v?.SECURITY_DEPOSIT)
          //   .reduce((a, b) => Number(a) + Number(b), 0)
          //   ?.toFixed(2)}
          hsn_data={
            <>
              {viewItem?.hsn_groups?.map((v, i) => (
                <tr key={i}>
                  <td
                    colSpan={2}
                    className={"td_1"}
                    style={{ textAlign: "left" }}
                  >
                    {v?.HSNCODE}
                  </td>
                  <td className={"td_1"}>
                    {Number(v?.TAXABLE_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>NA</td>
                  <td className={"td_1"}>
                    {/* {Number(v?.IGST_AMOUNT).toFixed(2)} */}
                    NA
                  </td>
                  <td className={"td_1"}>{v?.CGST_RATE}%</td>
                  <td className={"td_1"}>
                    {Number(v?.CGST_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>{v?.SGST_RATE}%</td>
                  <td className={"td_1"}>
                    {Number(v?.SGST_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>
                    {Number(v?.TOTAL_AMOUNT).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={2}
                  className={"td_1"}
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.TAXABLE_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                  {/* {Number(v?.TAXABLE_AMOUNT).toFixed(2)} */}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {/* {viewItem?.hsn_groups
                    ?.map((v) => v?.IGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)} */}
                  NA
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.CGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.SGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.TOTAL_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>
            </>
          }
          table_body={
            <>
              {viewItem?.details?.map((v, i) => (
                <tr key={i} className="both">
                  <td className="both">{i + 1}</td>
                  <td
                    className="both"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      minWidth: "230px",
                    }}
                  >
                    {`${v?.CAT_NAME} - ${v?.CAT_DESC}`}
                  </td>
                  <td className="both">{v?.CSTEH_NO}</td>
                  <td className="both">{v?.GSTP}</td>
                  <td className="both">{v?.QUANTITY}</td>
                  <td className="both">
                    {Number(
                      Number(v?.UNIT_RATE) +
                        Number(gst_calculate(v?.UNIT_RATE, v?.GSTP))
                    ).toFixed(2)}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  <td className="both">
                    {v?.UNITS == "1"
                      ? "NOS"
                      : v?.UNITS == "2"
                      ? "KGS"
                      : v?.UNITS == "3"
                      ? "SET"
                      : UOMData?.filter((val) => val?.UOM == v?.UNITS)?.[0]
                          ?.title}
                  </td>
                  <td className="both">{v?.DISC_UNIT_RATE}</td>
                  <td className="both">{v?.BASIC_PRICE}</td>
                </tr>
              ))}
              <tr>
                <td className="both"></td>
                <td className="both" style={{ textAlign: "left" }}></td>
                {Array(7)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td
                  className="both boldBorder"
                  style={{ borderTop: "1px solid black !important" }}
                >
                  {/* {viewItem?.SI_AMOUNT} */}
                  {viewItem?.details
                    ?.map((v) => v?.BASIC_PRICE)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OUTPUT CGST
                </td>
                {Array(7)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {viewItem?.details
                    ?.map((v) => v?.CGST_AMOUNT)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OUTPUT SGST
                </td>
                {Array(7)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {viewItem?.details
                    ?.map((v) => v?.SGST_AMOUNT)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  DEPOSITES
                </td>
                {Array(7)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {viewItem?.details
                    ?.map((v) => v?.DEPOSIT_AMOUNT)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  ROUND OFF (Sales)
                </td>
                {Array(7)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {(
                    Number(viewItem?.INV_AMOUNT).toFixed(0) -
                    Number(viewItem?.INV_AMOUNT)
                  ).toFixed(2)}
                </td>
              </tr>

              <tr style={{ height: "160px" }}>
                {Array(10)
                  .fill()
                  .map(() => (
                    <td className="both"></td>
                  ))}
              </tr>
              <tr>
                <td className="both border_top"></td>
                <td
                  className="both border_top"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td className="both border_top"></td>
                <td className="both border_top"></td>
                <td
                  className="both border_top"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  {viewItem?.details
                    ?.map((v) => v?.QUANTITY)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(0)}
                </td>
                {Array(4)
                  .fill()
                  .map((v, i) => (
                    <td className="both border_top"></td>
                  ))}
                <td className="both border_top" style={{ fontWeight: "bold" }}>
                  {Number(viewItem?.INV_AMOUNT).toFixed(0)}
                </td>
              </tr>
            </>
          }
          Total_tax={viewItem?.hsn_groups
            ?.map((v) => v?.TOTAL_AMOUNT)
            ?.reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          net_total={Number(viewItem?.INV_AMOUNT).toFixed(0)}
        />
      </div>

      {open1 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}

      {loading && <Loader loading={loading} />}
    </div>
  );
};
