import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import Table from "../../../components/table/Table";
import SmallModal from "../../../components/modal/SmallModal";
import axios from "axios";
import { staffMasterAPI, staffMasterAPI2 } from "../../../utils/apis";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import moment from "moment";
import { saveAs } from "file-saver";
import PageLoading from "../../../components/PageLoading";
import { DeleteIcon } from "../../../utils/utils";
import TableNew from "../../../components/table/TableWithPagination";
import { DateValidator, DateValidator2 } from "../../../utils/validation";
import { Pagination2 } from "../../../components/pagination/pagination";
import { get_staff_list } from "../masterdataSlice";
import { get_staff_list_payroll } from "../../Payroll/payrollSlice";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import Modal from "../../../components/modal/Modal";
import { EmailValidation, MobileValidation } from "../../../utils/validator";
import { Alerts } from "../../../components/Alerts/Alerts";

const eighteenYearsAgo = moment().subtract(18, "years").format("DD/MM/YYYY");
const eighteenYearsAgo2 = moment().subtract(18, "years").format("YYYY-MM-DD");

export const StaffMaster = ({ data = false }) => {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [addProductData, setAddProductData] = useState([]);
  const [alerts1, setAlerts1] = useState(false);
  const [alerts1Mess, setAlerts1Mess] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [flag, setFlag] = useState(false);


  const [showEdit, setShowEdit] = useState(false);
  const [EditItem, setEditItem] = useState({});
  const [salaryStatus, setSalaryStatus] = useState("salary");

  function getStaffCategory(value) {
    const categories = {
      0: "DELIVERY STAFF",
      1: "SHOWROOM STAFF",
      2: "GODOWN STAFF",
      3: "INSPECTOR",
      4: "MECHANIC",
      5: "OTHERS",
    };

    return categories[value] || "Invalid category";
  }

  // Example usage:

  const handleclick = () => {
    //    console.log(EditItem);
    //    console.log(staff_list, "abhishek");
  };

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const { staff_list, staff_loading } = useSelector(
    (state) => state.masterdata
  );

console.log("staff_list =====",staff_list);

  const { staff_payroll_list, staff_payroll_loading } = useSelector(
    (state) => state.payroll
  );
  const { today,user_id } = useSelector((state) => state.other);

  const handleDeleteVehicalMaster = async (deleteId) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteId);
    const response = await axios.post(staffMasterAPI, body);
    setShowLoader(false);
    setShowDeleteAlert(false);
    if (response.data.success) {
      toast(response.data.message, {
        position: "top-center",
      });
      if (staff_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_staff_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_staff_list(obj));
      }
    }
  };

  const handleAddProduct = () => {
    if (addProductData.length === 0) {
      setAddProductData((prevData) => [
        ...prevData,
        {
          id: addProductData.length + 1,
          empcode: "",
          empname: "",
          dob: "",
          designation: "",
          role: "",
          joining: "",
        },
      ]);
    } else {
      const data = valiDate();
      if (data.length == 0) {
        setAddProductData((prevData) => [
          ...prevData,
          {
            id: addProductData.length + 1,
            empcode: "",
            empname: "",
            dob: "",
            designation: "",
            role: "",
            joining: "",
          },
        ]);
      } else {
        setAlerts1(true);
        setAlerts1Mess(
          <Alerts data={data} />
        );
      }
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addProductData.filter((item) => item.id !== deleteId);
    setAddProductData(filterData);
  };

  const handleSaveProduct = async () => {
    const data = valiDate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addProductData.forEach((item) => {
          if (item) {
            body.append("empcode[]", item.empcode.trim());
            body.append("empname[]", item.empname.trim());
            body.append("dob[]", moment(item.dob).format("YYYY-MM-DD"));
            body.append("designation[]", item.designation.trim());
            body.append("role[]", item.role);
            body.append("joining[]", moment(item.joining).format("YYYY-MM-DD"));
          }
        });
        const response = await axios.post(staffMasterAPI, body);
        setShowLoader(false);
        if (response.data.success) {
          setAddProductData([]);
          toast(response.data.message, {
            position: "top-center",
          });
          store.dispatch(get_staff_list(obj));
        } else if (response.data.success == false) {
          setAlerts1(true);
          setAlerts1Mess(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setAlerts1(true);
      setAlerts1Mess(
        <Alerts data={data} />
      );
    }
  };

  const downloadExcelFile = () => {
    const excelContent = "Column1,Column2,Column3\nValue1,Value2,Value3";
    const blob = new Blob([excelContent], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "EBS_StaffData.xlsx");
  };

  const valiDate = () => {
    const data = [];
    var count = 1;
    const arr = [];
    if (addProductData?.length > 0) {
      for (let v of addProductData) {
        if (!v?.empcode || (v?.empcode && v?.empcode.trim()?.length === 0)) {
          data.push(`Please Enter Employee Code`);
        } else {
          arr.push(v?.empcode);
        }
        if (!v?.empname || (v?.empname && v?.empname.trim()?.length === 0)) {
          data.push(`Please Enter Employee Name`);
        }
        if (!v?.dob || (v?.dob && v?.dob?.length === 0)) {
          data.push(`Please Select Date of Birth`);
        }
        if (
          !v?.designation ||
          (v?.designation && v?.designation.trim()?.length === 0)
        ) {
          data.push(`Please Enter Designation`);
        }
        if (!v?.role || (v?.role && v?.role.trim()?.length === 0)) {
          data.push(`Please Enter Role`);
        }
        if (!v?.joining || (v?.joining && v?.joining?.length === 0)) {
          data.push(`Please Select Date of Join`);
        }
        count += 1;
      }
      const uniqueArray = arr.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      if (uniqueArray?.length != arr?.length) {
        data.push("Duplicate Vehicle no. not allowed");
      }
    } else {
      data.push("Please Add Product First");
    }
    return data;
  };
  const update = async () => {
    const data1 = valiDateEdit();
    if (data) {
      try {
        setShowLoader(true);
        const body = {
          AGENCY_ID: user_id,
          action: "saveOrUpdateStaffSalaryData",
          empId: EditItem?.ID,
          staffrole: flag ? "1" : "0",
          srno: "ST-0001",
          dsaltype: flag ? "1" : "0",
          empBasicPay: !flag ? EditItem?.BASIC_PAY : "",
          empHRA: !flag ? EditItem?.HRA : "",
          empConveyance: !flag ? EditItem?.CONVEYANCE_ALLOWANCE : "",
          empMedicalAllowance: !flag ? EditItem?.MEDICAL_ALLOWANCE : "",
          empOtherAllowance: !flag ? EditItem?.OTHER_ALLOWANCE : "",
          empOutstandLoansAdvances: !flag ? EditItem?.CL_OUTSTANDING_LOANS : "",
          comrate14_2kg: flag ? EditItem?.COMMISSION_RATE_14_2KG : "",
          comrate19kg: flag ? EditItem?.COMMISSION_RATE_19KG : "",
          dboyOtherAllowance: flag ? EditItem?.OTHER_ALLOWANCE : "",
          dboyOutstandLoansAdvances: flag ? EditItem?.CL_OUTSTANDING_LOANS : "",
        };

        const response = await staffMasterAPI2(body);
        if (response.success) {
          toast(response.message, {
            position: "top-center",
          });
          setShowEdit(false);
          store.dispatch(get_staff_list_payroll(obj));
          setEditItem({});
          setShowLoader(false);
        } else if (response.success == false) {
          setAlerts1(true);
          setAlerts1Mess(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (data1?.length == 0) {
        try {
          setShowLoader(true);
          const body = new FormData();
          body.append("action", "update");
          body.append("agencyid", user_id);
          body.append("id", EditItem?.ID);
          body.append("addhar", EditItem?.AADHAR);
          body.append("pan", EditItem?.PAN || "");
          body.append("bank_account", EditItem?.BANK_ACC_NO || "");
          body.append(
            "esic",
            EditItem?.ESIC_ELIGIBLE == 1 ? EditItem?.ESIC : "" || ""
          );
          body.append("contact_num", EditItem?.CONTACT_NO);
          body.append(
            "pfnum",
            EditItem?.PF_ELIGIBLE == 1 ? EditItem?.PF_NUM : "" || ""
          );
          body.append("uan_num", EditItem?.UAN_NUM || "");
          body.append("blood_group", EditItem?.BLOOD_GROUP || "");
          body.append("emer_mob", EditItem?.EMERGENCY_CONTACT_NO || "");
          body.append("email_id", EditItem?.EMAIL_ID || "");
          body.append("address", EditItem?.ADDRESS?.trim());
          body.append("esic_eligible", EditItem?.ESIC_ELIGIBLE);
          body.append("pf_eligible", EditItem?.PF_ELIGIBLE);
          const response = await axios.post(staffMasterAPI, body);
          setShowLoader(false);
          if (response.data.success) {
            toast(response.data.message, {
              position: "top-center",
            });
            setShowEdit(false);
            setEditItem({});
            store.dispatch(get_staff_list(obj));
          } else if (response.data.success == false) {
            setAlerts1(true);
            setAlerts1Mess(response.data.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setAlerts1(true);
        setAlerts1Mess(
          <Alerts data={data1} />
        );
      }
    }
  };

  const valiDateEdit = () => {
    const data1 = [];
    if (!data) {
      if (!EditItem?.AADHAR) {
        data1.push(`Please Enter Aadhar Number`);
      } else if (EditItem?.AADHAR?.length !== 12) {
        data1.push(`Invalid Aadhar`);
      }
      if (
        EditItem?.PAN &&
        (EditItem?.PAN?.length !== 10 ||
          !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(EditItem?.PAN))
      ) {
        data1.push(`Please Enter Valid Pan Number`);
      }
      
      if (
        EditItem?.BANK_ACC_NO &&
        (EditItem?.BANK_ACC_NO?.length < 11 ||
          EditItem?.BANK_ACC_NO?.length > 20)
      ) {
        data1.push(`Please Enter Valid Bank Account No.`);
      }

      if (!EditItem?.CONTACT_NO) {
        data1.push(`Contact Number is required`);
      } else if (
        EditItem?.CONTACT_NO?.length !== 10 ||
        !MobileValidation.test(EditItem?.CONTACT_NO)
      ) {
        data1.push(`Please Enter Valid Mobile Number`);
      }

      // if (EditItem?.PF_NUM && EditItem?.PF_NUM?.length !== 22) {
      //   data1.push(`Please Enter Valid PF Number`);
      // }
      if (EditItem?.UAN_NUM && EditItem?.UAN_NUM?.length !== 12) {
        data1.push(`Please Enter Valid UAN Number`);
      }
      if (
        EditItem?.EMERGENCY_CONTACT_NO &&
        EditItem?.EMERGENCY_CONTACT_NO != "" &&
        EditItem?.EMERGENCY_CONTACT_NO?.length !== 10 &&
        !MobileValidation.test(EditItem?.EMERGENCY_CONTACT_NO)
      ) {
        data1.push(`Please Enter Valid EMERGENCY CONTACT Number`);
      }
      if (EditItem?.EMAIL_ID && !EmailValidation.test(EditItem?.EMAIL_ID)) {
        data1.push(`Please Enter Valid Email`);
      }
      if (
        !EditItem?.ADDRESS ||
        (EditItem?.ADDRESS && EditItem?.ADDRESS?.trim()?.length == 0)
      ) {
        data1.push(`Please Enter Address`);
      } else if (EditItem?.ADDRESS?.trim()?.length < 25) {
        data1.push(`Address should be min 25 charactors`);
      }
      if (EditItem?.ESIC_ELIGIBLE == 1) {
        if (EditItem?.ESIC?.length == 0 || EditItem?.ESIC == null) {
          data1.push(`Please Enter ESIC Number`);
        } else if (
          EditItem?.ESIC?.length <= 16 ||
          EditItem?.ESIC?.length >= 21
        ) {
          data1.push(`Please Enter Valid ESIC Number`);
        }
      }
      if (EditItem?.PF_ELIGIBLE == 1) {
        if (EditItem?.PF_NUM?.length == 0 || EditItem?.PF_NUM == null) {
          data1.push(`Please Enter PF Number`);
        } else if (EditItem?.PF_NUM && EditItem?.PF_NUM?.length !== 22) {
          data1.push(`Please Enter Valid PF Number`);
        }
      }
    } else {
      if (flag) {
        if (EditItem?.COMMISSION_RATE_14_2KG?.length == 0) {
          data1.push("please enter commision rate for 14kg");
        }
        if (EditItem?.COMMISION_RATE_19KG?.length == 0) {
          data1.push("please enter commision rate for 19kg");
        }
        if (EditItem?.OTHER_ALLOWANCE?.length == 0) {
          data1.push("please enter other allowance");
        }
        if (EditItem?.CL_OUTSTANDING_LOANS?.length == 0) {
          data1.push("please enter CL_OUTSTANDING_LOANS");
        }
      } else {
        if (EditItem?.BASIC_PAY?.length == 0) {
          data1.push("please enter Basic pay");
        }
        if (EditItem?.HRA?.length == 0) {
          data1.push("please enter HRA");
        }
        if (EditItem?.CONVEYANCE_ALLOWANCE?.length == 0) {
          data1.push("please enter CONVEYANCE_ALLOWANCE");
        }
        if (EditItem?.MEDICAL_ALLOWANCE?.length == 0) {
          data1.push("please enter MEDICAL_ALLOWANCE");
        }
        if (EditItem?.OTHER_ALLOWANCE?.length == 0) {
          data1.push("please enter other allowance");
        }
        if (EditItem?.CL_OUTSTANDING_LOANS?.length == 0) {
          data1.push("please enter CL_OUTSTANDING_LOANS");
        }
      }
    }
    return data1;
  };

  useEffect(() => {
    if (!data) store.dispatch(get_staff_list(obj));
    else store.dispatch(get_staff_list_payroll(obj));
  }, [pageNo, entries]);

  return (
    <div className="container">
      <PageHeading
        title={"STAFF MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setOpen(true);
                handleAddProduct();
              }}
            />
            {addProductData?.length > 0 && (
              <SaveButton title={"SAVE"} onClick={handleSaveProduct} />
            )}
            {!data && (
              <>
                <Button onClick={downloadExcelFile} title={"DOWNLOAD EXCEL"} />
                <Button
                  onClick={() => setOpenUpload(true)}
                  title={"UPLOAD EXCEL"}
                />
              </>
            )}

            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/4FzTz5cY6bc",
                "https://youtu.be/JcUiQNwM4QY",
              ]}
            />
          </>
        }
      />
      {open && addProductData.length != 0 && (
        <>
          <Table
            mt={30}
            headData={[
              "EMPLOYEE CODE",
              "EMPLOYEE NAME",
              "DATE OF BIRTH",
              "DESIGNATION",
              "ROLE",
              "DATE OF JOINING",
              "ACTIONS",
            ]}
            body={
              <>
                {addProductData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        placeholder={"EMPLOYEE CODE"}
                        value={item.empcode}
                        onChange={(event) => {
                          if (event.target.value.length <= 6) {
                            const numericValue =
                              event.target.value.toUpperCase();
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                empcode: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"EMPLOYEE NAME"}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (input?.length <= 30) {
                            if (
                              /^([a-zA-Z ]+\s*){0,30}$/.test(input) ||
                              input === ""
                            ) {
                              setAddProductData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[index] = {
                                  ...updatedData[index],
                                  empname: input,
                                };
                                return updatedData;
                              });
                            }
                          }
                        }}
                        value={item.empname}
                      />
                    </td>
                    <td>
                      <Input
                        type={"date"}
                        max={eighteenYearsAgo2}
                        onChange={(event) => {
                          if (event.target.value?.length <= 10) {
                            const numericValue = event.target.value;
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                dob: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        onBlur={() => {
                          const lastProduct =
                            addProductData[addProductData?.length - 1];
                          if (lastProduct?.dob?.length == 10) {
                            if (
                              DateValidator(
                                moment(lastProduct?.dob).format("DD/MM/YYYY"),
                                eighteenYearsAgo,
                                "01/01/1970",
                                "DATE OF BIRTH",
                                `Please enter valid data for DATE OF BIRTH field (before ${eighteenYearsAgo} `
                              ) != true
                            ) {
                              setAlerts1Mess(
                                ...DateValidator(
                                  moment(lastProduct?.dob).format("DD/MM/YYYY"),
                                  eighteenYearsAgo,
                                  "01/01/1970",
                                  "DATE OF BIRTH",
                                  `Please enter valid data for DATE OF BIRTH field (before ${eighteenYearsAgo} `
                                )
                              );
                              setAlerts1(true);
                              setAddProductData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[index] = {
                                  ...updatedData[index],
                                  dob: "",
                                };
                                return updatedData;
                              });
                            }
                          }
                        }}
                        value={item.dob}
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"EMPLOYEE DESIGNATION"}
                        onChange={(event) => {
                          const numericValue = event.target.value;
                          if (numericValue?.length <= 15) {
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                designation: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        value={item.designation}
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.role}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              role: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">DELIVERY STAFF</option>
                            <option value="1">SHOWROOM STAFF</option>
                            <option value="2">GODOWN STAFF</option>
                            <option value="3">INSPECTOR</option>
                            <option value="4">MECHANIC</option>
                            <option value="5">OTHERS</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type={"date"}
                        onChange={(event) => {
                          if (event.target.value?.length <= 10) {
                            const numericValue = event.target.value;
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                joining: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        onBlur={() => {
                          const lastProduct =
                            addProductData[addProductData?.length - 1];
                          if (lastProduct?.joining?.length == 10) {
                            if (
                              DateValidator2(
                                moment(lastProduct?.joining).format(
                                  "DD/MM/YYYY"
                                ),
                                today,
                                "01/01/1970",
                                "Please enter valid data for DATE OF JOINING field",
                                "DATE OF JOINING Cant be a future date"
                              ) != true
                            ) {
                              setAlerts1Mess(
                                ...DateValidator2(
                                  moment(lastProduct?.joining).format(
                                    "DD/MM/YYYY"
                                  ),
                                  today,
                                  "01/01/1970",
                                  "Please enter valid data for DATE OF JOINING field",
                                  "DATE OF JOINING Cant be a future date"
                                )
                              );
                              setAlerts1(true);
                              setAddProductData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[index] = {
                                  ...updatedData[index],
                                  joining: "",
                                };
                                return updatedData;
                              });
                            }
                          }
                        }}
                        value={item.joining}
                      />
                    </td>
                    <td>
                      <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {staff_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          mt={5}
          nodata={
            !data
              ? staff_list?.length !== 0 && !staff_loading
                ? false
                : true
              : staff_payroll_list?.length !== 0 && !staff_payroll_loading
              ? false
              : true
          }
          headData={[
            "EMPLOYEE CODE",
            "EMPLOYEE NAME",
            "DATE OF BIRTH",
            "DESIGNATION",
            "ROLE",
            "DATE OF JOINING",
            "ACTIONS",
          ]}
          body={
            <>
              {data ? (
                <>
                  {staff_payroll_list?.length != 0 &&
                    staff_payroll_list?.STAFF_DATA?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditItem(item);
                            setShowEdit(true);
                          }}
                        >
                          {item?.EMP_CODE}
                        </td>
                        <td>{item?.EMP_NAME}</td>
                        <td>{moment(item?.DOB).format("DD/MM/YYYY")}</td>
                        <td>{item?.DESIGNATION}</td>
                        <td>{getStaffCategory(item?.ROLE)}</td>
                        <td>{moment(item?.DOJ).format("DD/MM/YYYY")}</td>
                        <td>
                          <DeleteIcon
                            onClick={() => {
                              setDeleteId(item?.ID);
                              setShowDeleteAlert(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {staff_list?.length != 0 &&
                    staff_list?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditItem(item);
                            setShowEdit(true);
                          }}
                        >
                          {item?.EMP_CODE}
                        </td>
                        <td>{item?.EMP_NAME}</td>
                        <td>{moment(item?.DOB).format("DD/MM/YYYY")}</td>
                        <td>{item?.DESIGNATION}</td>
                        <td>{item?.ROLE}</td>
                        <td>{moment(item?.DOJ).format("DD/MM/YYYY")}</td>
                        <td>
                          <DeleteIcon
                            onClick={() => {
                              setDeleteId(item?.ID);
                              setShowDeleteAlert(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </>
          }
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {openUpload && (
        <SmallModal
          okBtnHide={false}
          isOpen={openUpload}
          setIsOpen={setOpenUpload}
          style={{ minWidth: "30rem" }}
          okbtn={setOpenUpload}
          title={"UPLOAD MASTER DATA"}
          mess={
            <div className="pin-ver">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  color: "red",
                  margin: "0 0 1rem 0",
                  fontSize: "11px",
                }}
              >
                Please select your excel to upload cvo data
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <label style={{ width: "30%", fontSize: "13px" }}>
                    UPLOAD EXCEL
                  </label>
                  <div style={{ width: "70%" }}>
                    <Input value={inputValue.name} placeholder={""} />
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "0 1% ",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="label">
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      required
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const fileType = selectedFile.type;
                          if (
                            fileType ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            fileType === "application/vnd.ms-excel"
                          ) {
                            setInputValue(selectedFile);
                          } else {
                            alert(
                              "Please select a valid Excel file (either .xlsx or .xls)."
                            );
                            e.target.value = null;
                          }
                        }
                      }}
                    />

                    <span style={{ fontSize: "13px" }}>Browse</span>
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                  <Button title={"UPLOAD"} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteVehicalMaster(deleteId)}
          title={"Confirm"}
          mess={"Are You Sure you want to delete"}
        />
      )}

      {showEdit && (
        <Modal
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          title={"STAFF DETAILS"}
          body={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div className="staff-heading">
                <div style={{ color: "#145272", fontWeight: "600" }}>
                  PERSONAL INFO :
                </div>
                <div style={{ color: "#0c08d8", textWrap: "wrap" }}>
                  {EditItem?.EMP_CODE} - {EditItem?.EMP_NAME}
                </div>
              </div>
              <div className="credit-limit">
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    label={
                      <span>
                        AADHAR NUMBER<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder={"AADHAR NUMBER"}
                    value={EditItem?.AADHAR}
                    disabled={
                      staff_list?.filter((v) => v?.ID == EditItem?.ID)[0]
                        ?.AADHAR || data
                    }
                    onChange={(e) => {
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, ""); // Remove non-digits
                      const limitedInput = sanitizedInput.substring(0, 12); // Limit to 17 digits
                      setEditItem({
                        ...EditItem,
                        AADHAR: limitedInput,
                      });
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    label={"PAN NUMBER"}
                    placeholder={"PAN NUMBER"}
                    value={EditItem?.PAN}
                    onChange={(e) => {
                      if (e.target.value?.length < 11) {
                        setEditItem({
                          ...EditItem,
                          PAN: e.target.value.toUpperCase(),
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    label={"BANK ACCOUNT"}
                    placeholder={"BANK ACCOUNT"}
                    value={EditItem?.BANK_ACC_NO}
                    onChange={(e) => {
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, "");
                      const limitedInput = sanitizedInput.substring(0, 20);
                      setEditItem({
                        ...EditItem,
                        BANK_ACC_NO: limitedInput,
                      });
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    label={
                      <span>
                        CONTACT NUMBER<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value={EditItem?.CONTACT_NO}
                    onChange={(e) => {
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, "");
                      const limitedInput = sanitizedInput.substring(0, 10);
                      setEditItem({
                        ...EditItem,
                        CONTACT_NO: limitedInput,
                      });
                    }}
                    placeholder={"CONTACT NUMBER"}
                  />
                </div>

                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    label={"UAN NUMBER"}
                    placeholder={"UAN NUMBER"}
                    value={EditItem?.UAN_NUM}
                    onChange={(e) => {
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, "");
                      const limitedInput = sanitizedInput.substring(0, 12);
                      setEditItem({ ...EditItem, UAN_NUM: limitedInput });
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    disabled={data}
                    label="BLOOD GROUP"
                    value={EditItem?.BLOOD_GROUP}
                    onChange={(e) => {
                      setEditItem({ ...EditItem, BLOOD_GROUP: e.target.value });
                    }}
                    options={
                      <>
                        <option hidden value={"0"}>
                          SELECT
                        </option>
                        <option value="1"> A + </option>
                        <option value="2"> A - </option>
                        <option value="3"> B + </option>
                        <option value="4"> B - </option>
                        <option value="5"> O + </option>
                        <option value="6"> O - </option>
                        <option value="7"> AB + </option>
                        <option value="8"> AB - </option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    value={EditItem?.EMERGENCY_CONTACT_NO}
                    onChange={(e) => {
                      const input = e.target.value;
                      const sanitizedInput = input.replace(/\D/g, "");
                      const limitedInput = sanitizedInput.substring(0, 10);
                      setEditItem({
                        ...EditItem,
                        EMERGENCY_CONTACT_NO: limitedInput,
                      });
                    }}
                    label={"EMERGENCY NUMBER"}
                    placeholder={"EMERGENCY NUMBER"}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    value={EditItem?.EMAIL_ID}
                    onChange={(e) => {
                      setEditItem({ ...EditItem, EMAIL_ID: e.target.value });
                    }}
                    label={"EMAIL ID"}
                    placeholder={"EMAIL ID"}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={data}
                    value={EditItem?.ADDRESS}
                    onChange={(e) => {
                      setEditItem({ ...EditItem, ADDRESS: e.target.value });
                    }}
                    label={
                      <span>
                        ADDRESS <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder={"ADDRESS"}
                  />
                </div>
                <div
                  style={{
                    width: "240px",
                    padding: " .15rem .5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      gap: "1rem",
                    }}
                  >
                    <span>ESIC ELIGIBLE</span>
                    <span>
                      <input
                        disabled={data}
                        style={{ cursor: "pointer" }}
                        checked={
                          Number(EditItem?.ESIC_ELIGIBLE) == 0 ? false : true
                        }
                        onChange={(e) => {
                          setEditItem({
                            ...EditItem,
                            ESIC_ELIGIBLE:
                              Number(EditItem?.ESIC_ELIGIBLE) == 0 ? 1 : 0,
                          });
                        }}
                        type="checkbox"
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      gap: "1rem",
                    }}
                  >
                    <span>PF ELIGIBLE</span>
                    <span style={{ paddingLeft: ".81rem" }}>
                      <input
                        disabled={data}
                        style={{ cursor: "pointer" }}
                        checked={
                          Number(EditItem?.PF_ELIGIBLE) == 0 ? false : true
                        }
                        onChange={(e) => {
                          setEditItem({
                            ...EditItem,
                            PF_ELIGIBLE:
                              Number(EditItem?.PF_ELIGIBLE) == 0 ? 1 : 0,
                          });
                        }}
                        type="checkbox"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="credit-limit">
                {EditItem?.ESIC_ELIGIBLE == 1 && (
                  <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                    <Input
                      disabled={data}
                      label={"ESIC NUMBER"}
                      placeholder={"ESIC NUMBER"}
                      value={EditItem?.ESIC}
                      onChange={(e) => {
                        const input = e.target.value;
                        const sanitizedInput = input.replace(/\D/g, "");
                        if (e.target.value?.length <= 20) {
                          setEditItem({ ...EditItem, ESIC: sanitizedInput });
                        }
                      }}
                    />
                  </div>
                )}
                {EditItem?.PF_ELIGIBLE == 1 && (
                  <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                    <Input
                      disabled={data}
                      label={"PF NUMBER"}
                      placeholder={"PF NUMBER"}
                      value={EditItem?.PF_NUM}
                      onChange={(e) => {
                        if (e.target.value?.length <= 22) {
                          setEditItem({ ...EditItem, PF_NUM: e.target.value });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              {data && (
                <>
                  <div className="staff-heading">
                    <div style={{ color: "#145272", fontWeight: "600" }}>
                      SALARY DETAILS
                    </div>
                    <div
                      style={{
                        color: "#0c08d8",
                        textWrap: "wrap",
                        display: "flex",
                        gap: "15px",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                      onClick={handleclick}
                    >
                      {EditItem?.STAFF_TYPE != null ? (
                        <>{""}</>
                      ) : (
                        <>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <input
                              type="radio"
                              checked={!flag}
                              onClick={() => {
                                // setEditItem({});
                                setFlag(false);
                              }}
                            />
                            <div
                              style={{
                                color: "#145272",
                                fontWeight: "600",
                                fontSize: "18px",
                              }}
                            >
                              Salary
                            </div>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <input
                              type="radio"
                              checked={flag}
                              onClick={() => {
                                // setEditItem({});
                                setFlag(true);
                              }}
                            />
                            <div
                              style={{
                                color: "#145272",
                                fontWeight: "600",
                                fontSize: "18px",
                              }}
                            >
                              Commision
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {!flag && (
                    <div className="credit-limit credit-limit1">
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              BASIC PAY<span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"BASIC PAY"}
                          value={EditItem?.BASIC_PAY}
                          onChange={(e) => {
                            if (e.target.value?.length < 11) {
                              const input = e.target.value;
                              const sanitizedInput = input.replace(/\D/g, "");
                              const limitedInput = sanitizedInput.substring(
                                0,
                                20
                              );
                              setEditItem({
                                ...EditItem,
                                BASIC_PAY: limitedInput,
                              });
                            }
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              HOUSE RENTAL ALLOWANCE
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"HOUSE RENTAL ALLOWANCE"}
                          value={EditItem?.HRA}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              20
                            );
                            setEditItem({
                              ...EditItem,
                              HRA: limitedInput,
                            });
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              CONVEYANCE ALLOWANCE
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"CONVEYANCE ALLOWANCE"}
                          value={EditItem?.CONVEYANCE_ALLOWANCE}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              20
                            );
                            setEditItem({
                              ...EditItem,
                              CONVEYANCE_ALLOWANCE: limitedInput,
                            });
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              MEDICAL ALLOWANCE
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          value={EditItem?.MEDICAL_ALLOWANCE}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              10
                            );
                            setEditItem({
                              ...EditItem,
                              MEDICAL_ALLOWANCE: limitedInput,
                            });
                          }}
                          placeholder={"MEDICAL ALLOWANCE"}
                        />
                      </div>

                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              OTHER ALLOWANCE
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"OTHER ALLOWANCE"}
                          value={EditItem?.OTHER_ALLOWANCE}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              20
                            );
                            setEditItem({
                              ...EditItem,
                              OTHER_ALLOWANCE: limitedInput,
                            });
                          }}
                        />
                      </div>

                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              OUTSTANDING LOANS/ ADVANCES
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"OUTSTANDING LOANS/ ADVANCES"}
                          value={EditItem?.CL_OUTSTANDING_LOANS}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              12
                            );
                            setEditItem({
                              ...EditItem,
                              CL_OUTSTANDING_LOANS: limitedInput,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {flag && (
                    <div className="credit-limit credit-limit1">
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              COMMISSION RATE : 14.2 KG
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"COMMISSION RATE : 14.2 KG"}
                          value={EditItem?.COMMISSION_RATE_14_2KG}
                          onChange={(e) => {
                            if (e.target.value?.length < 11) {
                              const input = e.target.value;
                              const sanitizedInput = input.replace(/\D/g, "");
                              const limitedInput = sanitizedInput.substring(
                                0,
                                20
                              );
                              setEditItem({
                                ...EditItem,
                                COMMISSION_RATE_14_2KG: limitedInput,
                              });
                            }
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              COMMISSION RATE :19 KG
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"COMMISSION RATE :19 KG"}
                          value={EditItem?.COMMISSION_RATE_19KG}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              20
                            );
                            setEditItem({
                              ...EditItem,
                              COMMISSION_RATE_19KG: limitedInput,
                            });
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              OTHER ALLOWANCE
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          placeholder={"OTHER ALLOWANCE"}
                          value={EditItem?.OTHER_ALLOWANCE}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              20
                            );
                            setEditItem({
                              ...EditItem,
                              OTHER_ALLOWANCE: limitedInput,
                            });
                          }}
                        />
                      </div>
                      <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                        <Input
                          label={
                            <span>
                              OUTSTANDING LOANS/ ADVANCES
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          value={EditItem?.CL_OUTSTANDING_LOANS}
                          onChange={(e) => {
                            const input = e.target.value;
                            const sanitizedInput = input.replace(/\D/g, "");
                            const limitedInput = sanitizedInput.substring(
                              0,
                              10
                            );
                            setEditItem({
                              ...EditItem,
                              CL_OUTSTANDING_LOANS: limitedInput,
                            });
                          }}
                          placeholder={"OUTSTANDING LOANS/ ADVANCES"}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  marginTop: "1rem",
                  gap: ".5rem",
                }}
              >
                <SaveButton onClick={update} />
              </div>
            </div>
          }
        />
      )}
      {alerts1 && (
        <SmallModal
          isOpen={alerts1}
          setIsOpen={setAlerts1}
          title={"alert"}
          mess={alerts1Mess}
          okbtn={setAlerts1Mess}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

// update(agencyid, id, addhar, pan, bank_account, esic, contact_num, pfnum, uan_num, blood_group, emer_mob, email_id, address, esic_eligible, pf_eligible)
