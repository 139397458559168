import React, { useMemo, useState } from "react";
import Dropdown from "../../components/inputs/Dropdown";
import PageHeading from "../../components/PageHeading";
import { BackButton, Button, HelpButton } from "../../components/btn/Buttons";
import "./Gst.css";
import { Alerts } from "../../components/Alerts/Alerts";
import { gstr_1_API } from "../../utils/apis";
import { useSelector } from "react-redux";
import SmallModal from "../../components/modal/SmallModal";
import { downloadFile } from "../../utils/data";
import { base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import useLastFourFinancialYears from "../../utils/Hook/financialYearHook";
const GSTR1 = () => {
  const [buttonClick, setButtonClick] = useState("GSTR1 REPORT :");
  const [addData, setAddData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const { user_id, today } = useSelector((state) => state.other);
  const currentYear = new Date().getFullYear();

  const handleInputChange = (key) => (event) => {
    if (event.target.value.length <= 10) {
      setAddData({
        ...addData,
        [key]: event.target.value,
      });
    }
  };

  const options = useLastFourFinancialYears(4);

  const validate = () => {
    const data = [];
    if (!addData?.month || (addData?.month && addData?.month == "")) {
      data.push("Please Select Month ");
    }
    if (!addData?.year || (addData?.year && addData?.year == "")) {
      data.push("Please Select Year ");
    }
    return data;
  };
  const download_report = async () => {
    const data = await validate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("agencyid", user_id);
        body.append("month", addData?.month);
        body.append("year", addData?.year);
        body.append("action", "list");
        const res = await gstr_1_API(body);
        setLoading(false);
        if (res.success) {
          setAddData({});
          await downloadFile("https://lpgtest.pmpframe.com/api/" + res.file);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    } else {
      setLoading(false);
      setOpen(true);
      setMess(<Alerts data={data} />);
    }
  };
  return (
    <div className="container">
      <div className="ReportsMain">
        <PageHeading
          title={buttonClick.toUpperCase()}
          right={
            <div className="ReportMenu-btn">
              <div>
                <button
                  onClick={() => setButtonClick("GSTR1 REPORT : OLD GSTR")}
                  className="GST-btn"
                >
                  OLD GSTR
                </button>
              </div>
              <div></div>
              <div>
                <BackButton />
              </div>
              <div>
                <HelpButton
                  link={[
                    "https://youtu.be/A971FsmllM4",
                    "https://youtu.be/v5AAVwSlxqs",
                  ]}
                />
              </div>
            </div>
          }
        />
      </div>
      <div style={{ height: "30px" }}></div>

      {buttonClick == "GSTR1 REPORT : OLD GSTR" && (
        <>
          <div className="tb1">
            <div className="GSTR1Mian">
              <div className="DR">
                <Dropdown
                  label={"MONTH"}
                  value={addData?.month}
                  onChange={handleInputChange("month")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value={"1"}>JAN</option>
                      <option value={"2"}>FEB</option>
                      <option value={"3"}>MAR</option>
                      <option value={"4"}>APR</option>
                      <option value={"5"}>MAY</option>
                      <option value={"6"}>JUN</option>
                      <option value={"7"}>JUL</option>
                      <option value={"8"}>AUG</option>
                      <option value={"9"}>SEP</option>
                      <option value={"10"}>OCT</option>
                      <option value={"11"}>NOV</option>
                      <option value={"12"}>DEC</option>
                    </>
                  }
                />
              </div>

              <div className="DR">
                <Dropdown
                  label={"YEAR"}
                  value={addData?.year}
                  onChange={handleInputChange("year")}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {options}
                    </>
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <button className="GST-btn" onClick={download_report}>
                  GENERATE GSTR1
                </button>
                <button className="GST-btn" onClick={download_report}>
                  GENERATE ACCESS TOKEN
                </button>
                <button className="GST-btn" onClick={download_report}>
                  GET OTP
                </button>
                <button className="GST-btn" onClick={download_report}>
                  VERIFY OTP
                </button>
                <button className="GST-btn" onClick={download_report}>
                  GET IP ADDRESS
                </button>
                <button className="GST-btn" onClick={download_report}>
                  GET REAL IP ADDRESS
                </button>
                <button className="GST-btn" onClick={download_report}>
                  CHECK DATA
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* condition2 */}

      {buttonClick == "GSTR1 REPORT : NEW GSTR" && (
        <>
          <div className="tb1">
            <div className="GSTR1Mian">
              <div className="DR">
                <Dropdown
                  label={"MONTH"}
                  value={""}
                  options={
                    <>
                      <option>SELECT</option>
                      <option>JAN</option>
                      <option>FEB</option>
                      <option>MAR</option>
                      <option>APR</option>
                      <option>MAY</option>
                      <option>JUN</option>
                      <option>JUL</option>
                      <option>AUG</option>
                      <option>SEP</option>
                      <option>OCT</option>
                      <option>NOV</option>
                      <option>DEC</option>
                    </>
                  }
                />
              </div>

              <div className="DR">
                <Dropdown
                  label={"YEAR"}
                  value={""}
                  options={
                    <>
                      <option>SELECT</option>
                      <option>2019</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>2026</option>
                      <option>2027</option>
                      <option>2028</option>
                      <option>2029</option>
                      <option>2030</option>
                    </>
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <button className="GST-btn">FETCH ANX1</button>
                <button className="GST-btn">GENERATE ANX1</button>
                <button className="GST-btn">FETCH PURCHASE REGISTER</button>
                <button className="GST-btn">GENERATE PURCHASE REGISTER</button>
              </div>
            </div>
          </div>
        </>
      )}
      {buttonClick == "GSTR1 REPORT :" && (
        <>
          <p style={{ fontSize: "11px" }}>
            Please Select Either OLD GSTR or NEW GSTR inorder to process your
            GSTR details..
          </p>
        </>
      )}

      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}
      {Loading && <Loader />}
    </div>
  );
};

export default GSTR1;
