import { useEffect, useRef } from "react";

const SearchApiHook = (fetchData, search, prevSearch, dependencies) => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    // if (isInitialRender.current) {
    //   isInitialRender.current = false;
    //   return;
    // }

    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        fetchData();
      }, 300);
    } else {
      fetchData();
    }

    return () => {
      clearTimeout(delay);
    };
  }, dependencies);
};

export default SearchApiHook;
