import React from "react";

export const CheckBox = ({ Checked, onChange, lable, value }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: ".25rem",
      }}
    >
      <span>
        <input
          style={{ cursor: "pointer", height: "13px", width: "13px" }}
          checked={Checked}
          onChange={onChange}
          type="checkbox"
          value={value}
        />
      </span>
      <span
        style={{
          fontSize: "13px",
          color: "#6f6f6f",
          cursor: "pointer",
          fontWeight: 700,
          paddingBottom: ".25rem",
        }}
        onClick={onChange}
      >
        {lable?.toUpperCase()}
      </span>
    </div>
  );
};
