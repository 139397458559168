import { useEffect, useState } from "react";
import { FaTrashCan } from "react-icons/fa6";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

// export const base_url = "https://mylpg.pmpframe.com/api/";
export const base_url = "https://lpglive.pmpframe.com/api/";

// export const base_url = "https://lpgtest.pmpframe.com/api/";

const userIdString = localStorage.getItem("userId");
export const userId = JSON.parse(userIdString)?.aid;

export const DeleteIcon = ({ onClick }) => {
  return (
    <FaTrashCan color="#f00" size={20} cursor={"pointer"} onClick={onClick} />
  );
};

export const MediumText = ({ title }) => {
  return <div className="mediumtxt">{title}</div>;
};
export const Text = ({ children, fs, color, fw, flex }) => {
  return (
    <div
      className="txt"
      style={{ fontSize: fs, color: color, fontWeight: fw, flex: flex }}
    >
      {children}
    </div>
  );
};

export const Radio = ({ title, show, setShow, disabled = false }) => {
  // const [show, setShow] = useState(false);
  return (
    <div
      style={{ padding: 5, cursor: disabled ? "default" : "pointer", gap: 5 }}
      className="df aic "
      onClick={() => {
        if (!disabled) {
          setShow(!show);
        }
      }}
    >
      <div
        style={{
          opacity: disabled ? 0.5 : 1,
          height: 13,
          width: 13,
          borderRadius: 20,
          border: `1px solid ${show ? "#007bff" : "#d1d1d1"}`,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          display: "flex",
        }}
      >
        {show && (
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 20,
              backgroundColor: "#007bff",
            }}
          ></div>
        )}
      </div>
      <div style={{ fontSize: "13px", color: "#6f6f6f", fontWeight: 700 }}>
        {title}
      </div>
    </div>
  );
};

// NP Utils

export const RadioNP = ({
  title,
  ShowValue,
  setShowValue,
  disabled = false,
  fontSize,
}) => {
  const [show, setShow] = useState(ShowValue);

  useEffect(() => {
    setShow(ShowValue);
  }, [ShowValue]);

  return (
    <div
      style={{ padding: 5, cursor: "pointer", gap: 5 }}
      className="df aic"
      onClick={() => {
        if (!disabled) {
          setShowValue(title);
        }
      }}
    >
      <div
        style={{
          height: 13,
          width: 13,
          borderRadius: 20,
          border: `1px solid ${show === title ? "#007bff" : "#d1d1d1"}`,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          display: "flex",
        }}
      >
        {show === title && (
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 20,
              backgroundColor: "#007bff",
            }}
          ></div>
        )}
      </div>
      <div
        style={{
          fontSize: fontSize ? fontSize : "13px",
          color: "#6f6f6f",
          fontWeight: 700,
        }}
      >
        {title}
      </div>
    </div>
  );
};

export const RadioTurnNP = ({
  title,
  ShowValue,
  setShowValue,
  value,
  disabled = false,
}) => {
  const [show, setShow] = useState(ShowValue);
  useEffect(() => {
    setShow(ShowValue);
  }, [ShowValue]);

  return (
    <div
      style={{ padding: 5, cursor: "pointer", gap: 5 }}
      className="df aic"
      onClick={
        () => {
          if (!disabled) {
            setShowValue(value);
          }
        } // Use setShowValue to update the value
      }
    >
      <div
        style={{
          height: 13,
          width: 13,
          borderRadius: 20,
          border: `1px solid ${show == value ? "#007bff" : "#d1d1d1"}`,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          display: "flex",
          opacity: disabled ? ".5" : "1",
        }}
      >
        {show == value && (
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 20,
              backgroundColor: "#007bff",
            }}
          ></div>
        )}
      </div>
      <div style={{ fontSize: "13px", color: "#6f6f6f", fontWeight: 700 }}>
        {title}
      </div>
    </div>
  );
};

// export const exportPDF = ({
//   table_headers,
//   table_data,
//   tableName,
//   fileName,
//   table_bottom,
// }) => {
//   const unit = "pt";
//   const size = "A3"; // Use A1, A2, A3 or A4
//   const orientation = "landscape"; // portrait or landscape

//   const marginLeft = 40;
//   const doc = new jsPDF(orientation, unit, size);

//   doc.setFontSize(15);

//   const title = { tableName };
//   const headers = [table_headers];
//   const data = table_data;

//   let content = {
//     startY: 50,
//     head: headers,
//     body: data,
//     head: headers,
//   };

//   doc.text(tableName, marginLeft, 40);
//   doc.autoTable(content);
//   doc.save(`${fileName}.pdf`);
// };

export const exportPDF = ({
  table_headers,
  table_data,
  tableName,
  fileName,
  table2 = false,
  table_headers_2,
  table_data_2,
}) => {

  const unit = "pt";
  const size = "A3"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = tableName;

  // First table content
  const headers_1 = [table_headers];
  const data_1 = table_data;

  // Second table content
  const headers_2 = [table_headers_2];
  const data_2 = table_data_2;

  // First table
  doc.text(title, marginLeft, 40);
  doc.autoTable({
    startY: 60,
    head: headers_1,
    body: data_1,
    margin: { top: 10 },
  });

  // Second table (starts after the first table)
  if (table2) {
    const finalY = doc.lastAutoTable.finalY || 60; // The last y coordinate of the previous table
    doc.autoTable({
      startY: finalY + 30, // Add some space between the tables
      head: headers_2,
      body: data_2,
      margin: { top: 10 },
    });
  }

  doc.save(`${fileName}.pdf`);
};

export const downloadExcel = (data, headers, fileName) => {
  const mergedHeader = [fileName]; // Merged cell with the fileName

  const worksheetData = [mergedHeader, headers, ...data]; // Combine merged header, individual headers, and data

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Add styling to the cells
  worksheet["!cols"] = headers.map(() => ({ width: 15 })); // Set column width
  worksheet["!rows"] = [{ hpx: 30 }, { hpx: 30 }]; // Set row height for merged header and individual headers

  // Customize cell styles
  worksheet["A1"].s = {
    font: { bold: true },
    alignment: { horizontal: "center", vertical: "center" },
  }; // Make cell A1 bold and center align text

  // Merge cells for the first row (merged header)
  worksheet["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } },
  ]; // Merge cells from A1 to Q1 (assuming 17 columns)

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export const downloadExcel1 = (
//   data1, headers1, fileName,
//   data2, headers2,

// ) => {
//   // Create merged header rows with the table names
//   const mergedHeader1 = [fileName];
//   const mergedHeader2 = [fileName];

//   // Combine the data with headers and merged headers
//   const worksheetData = [
//     mergedHeader1,
//     headers1,
//     ...data1,
//     [], // Empty row for spacing
//     mergedHeader2,
//     headers2,
//     ...data2,
//   ];

//   // Convert the data to a worksheet
//   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//   // Set column widths (adjust as needed)
//   const maxColumns = Math.max(headers1.length, headers2.length);
//   worksheet["!cols"] = Array(maxColumns).fill({ width: 15 }); // Set column width for the maximum number of columns

//   // Set row heights (adjust as needed)
//   worksheet["!rows"] = [{ hpx: 30 }, { hpx: 20 }]; // Set row height for merged headers and individual headers

//   // Apply styles to the merged header cells
//   const applyHeaderStyles = (rowIndex, headerLength) => {
//     const startCell = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
//     if (!worksheet[startCell]) worksheet[startCell] = {}; // Ensure cell exists
//     worksheet[startCell].s = {
//       font: { bold: true, sz: 30 },
//       alignment: { horizontal: "center", vertical: "center" },
//     }; // Make cell bold, center align text, and set font size

//     // Merge cells for the merged header
//     worksheet["!merges"] = [
//       ...worksheet["!merges"] || [],
//       { s: { r: rowIndex, c: 0 }, e: { r: rowIndex, c: headerLength - 1 } }
//     ];
//   };

//   // Apply styles to the individual header rows
//   const applyIndividualHeaderStyles = (rowIndex, headers) => {
//     headers.forEach((header, index) => {
//       const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: index });
//       if (!worksheet[cellAddress]) worksheet[cellAddress] = {}; // Ensure cell exists
//       worksheet[cellAddress].s = {
//         font: { bold: true },
//         alignment: { horizontal: "center", vertical: "center" },
//       }; // Make header cells bold and center align text
//     });
//   };

//   // Apply styles for the first table
//   applyHeaderStyles(0, headers1.length); // Merged header row index 0
//   applyIndividualHeaderStyles(1, headers1); // Individual header row index 1

//   // Apply styles for the second table
//   const secondTableStartRow = data1.length + 3; // Calculate start row for the second table (data1.length + 2 for headers and 1 for spacing)
//   applyHeaderStyles(secondTableStartRow, headers2.length); // Merged header row index
//   applyIndividualHeaderStyles(secondTableStartRow + 1, headers2); // Individual header row index

//   // Convert the workbook to a binary array and create a Blob for download
//   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });

//   // Create a link element and trigger a download
//   const url = URL.createObjectURL(blob);
//   const link = document.createElement("a");
//   link.href = url;
//   link.download = `${fileName}.xlsx`;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

export const downloadExcel1 = (data1, headers1, fileName, data2, headers2) => {
  // Create merged header rows with the table names
  const mergedHeader1 = [fileName];
  const mergedHeader2 = [fileName];

  // Combine the data with headers and merged headers
  const worksheetData = [
    mergedHeader1,
    headers1,
    ...data1,
    [], // Empty row for spacing
    mergedHeader2,
    headers2,
    ...data2,
  ];

  // Convert the data to a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Set column widths (adjust as needed)
  const maxColumns = Math.max(headers1.length, headers2.length);
  worksheet["!cols"] = Array(maxColumns).fill({ width: 20 }); // Set column width for the maximum number of columns

  // Set row heights (adjust as needed)
  worksheet["!rows"] = [
    { hpx: 30 }, // Row height for the first merged header
    { hpx: 20 }, // Row height for the first individual headers
    ...data1.map(() => ({ hpx: 20 })), // Row height for each data row in the first table
    { hpx: 10 }, // Row height for the empty spacing row
    { hpx: 30 }, // Row height for the second merged header
    { hpx: 20 }, // Row height for the second individual headers
    ...data2.map(() => ({ hpx: 20 })), // Row height for each data row in the second table
  ];

  // Apply styles to the merged header cells
  const applyHeaderStyles = (rowIndex, headerLength) => {
    const startCell = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
    if (!worksheet[startCell]) worksheet[startCell] = {}; // Ensure cell exists
    worksheet[startCell].s = {
      font: { bold: true, sz: 30 },
      alignment: { horizontal: "center", vertical: "center", wrapText: true },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    }; // Make cell bold, center align text, and set font size

    // Merge cells for the merged header
    worksheet["!merges"] = [
      ...(worksheet["!merges"] || []),
      { s: { r: rowIndex, c: 0 }, e: { r: rowIndex, c: headerLength - 1 } },
    ];
  };

  // Apply styles to the individual header rows
  const applyIndividualHeaderStyles = (rowIndex, headers) => {
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: index });
      if (!worksheet[cellAddress]) worksheet[cellAddress] = {}; // Ensure cell exists
      worksheet[cellAddress].s = {
        font: { bold: true },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      }; // Make header cells bold and center align text
    });
  };

  // Apply styles for the data rows
  const applyDataRowStyles = (startRowIndex, rowCount) => {
    for (let i = 0; i < rowCount; i++) {
      const rowIndex = startRowIndex + i;
      const rowLength = worksheetData[rowIndex + 2]
        ? worksheetData[rowIndex + 2].length
        : 0;
      for (let j = 0; j < rowLength; j++) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 2, c: j });
        if (!worksheet[cellAddress]) worksheet[cellAddress] = {}; // Ensure cell exists
        worksheet[cellAddress].s = {
          alignment: { wrapText: true },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      }
    }
  };

  // Apply styles for the first table
  applyHeaderStyles(0, headers1.length); // Merged header row index 0
  applyIndividualHeaderStyles(1, headers1); // Individual header row index 1
  applyDataRowStyles(0, data1.length); // Data rows for the first table

  // Apply styles for the second table
  const secondTableStartRow = data1.length + 3; // Calculate start row for the second table (data1.length + 2 for headers and 1 for spacing)
  applyHeaderStyles(secondTableStartRow, headers2.length); // Merged header row index
  applyIndividualHeaderStyles(secondTableStartRow + 1, headers2); // Individual header row index
  applyDataRowStyles(secondTableStartRow + 2, data2.length); // Data rows for the second table

  // Convert the workbook to a binary array and create a Blob for download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a link element and trigger a download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const BankCodeFinder = (BANK_CODE) => {
  if (BANK_CODE === "TAR ACCOUNT") {
    return "LOAD ACCOUNT";
  } else if (BANK_CODE === "STAR ACCOUNT") {
    return "SV/TV ACCOUNT";
  } else if (BANK_CODE === "CASH ACCOUNT") {
    return "CASH ACCOUNT";
  } else if (BANK_CODE === "SUSPENSE ACCOUNT") {
    return "SUSPENSE ACCOUNT";
  } else {
    return BANK_CODE;
  }
};

// export const downloadExcel = (data, headers, fileName) => {
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//   // Add styling to the cells
//   worksheet["!cols"] = headers.map(() => ({ width: 20 })); // Set column width
//   worksheet["!rows"] = [{ hpx: 30 }]; // Set row height

//   // Customize cell styles
//   worksheet["A1"].s = { font: { bold: true } }; // Example: make cell A1 bold

//   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   const url = URL.createObjectURL(blob);
//   const link = document.createElement("a");
//   link.href = url;
//   link.download = `${fileName}.xlsx`;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// export const exportPDF = ({
//   table_headers,
//   table_data,
//   tableName,
//   fileName,
//   table_bottom,
// }) => {
//   const unit = "pt";
//   const size = "A3"; // Use A1, A2, A3 or A4
//   const orientation = "landscape"; // portrait or landscape

//   const marginLeft = 40;
//   const doc = new jsPDF(orientation, unit, size);

//   doc.setFontSize(15);

//   const title = { tableName };
//   const headers = [table_headers];
//   const data = table_data;

//   let content = {
//     startY: 50,
//     head: headers,
//     body: data,
//     head: headers,
//   };

//   doc.text(tableName, marginLeft, 40);
//   doc.autoTable(content);
//   doc.save(`${fileName}.pdf`);
// };
