import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import { useSelector } from "react-redux";
import Table from "../../components/table/Table";
import store from "../../redux/store";
import { get_sales_report_list } from "./transactionReportSlice";
import { DateValidator2 } from "../../utils/validation";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import { sales_report_api } from "../../utils/apis";
import { Pagination2 } from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import { downloadExcel, exportPDF } from "../../utils/utils";
import { get_customer_vender_list } from "../MasterData/masterdataSlice";
import { Alerts } from "../../components/Alerts/Alerts";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
const SalesReport = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("50");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [purchase_report_item_list, setPurchase_report_item_list] = useState(
    []
  );

  console.table(purchase_report_item_list);

  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );

  const [totalData, setTotalData] = useState({});
  const { firm_name } = useSelector((state) => state.profile);
  const { sales_report_list } = useSelector((state) => state.transactionReport);
  const { eff_date, user_id, today } = useSelector((state) => state.other);

  useEffect(() => {
    store.dispatch(get_sales_report_list());
    store.dispatch(get_transition_complete_list());
  }, []);

  useEffect(() => {
    if (showData) {
      get_list(addDataPre);
    }
  }, [pageNo, entries]);

  const table_data = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.SR_NO,
        v?.INV_DATE,
        v?.GODOWN_NAME || "-",
        `${v?.CAT_NAME} - ${v?.CAT_DESC}`,
        v?.HSN_CODE,
        v?.GSTP,
        v?.CVO_NAME,
        v?.CVO_TIN || "NA",
        v?.EMP_NAME,
        v?.QUANTITY,
        v?.PREPAID,
        v?.PSV,
        v?.PREPAID,
        "NOS",
        v?.UNIT_RATE,
        v?.DISC_UNIT_RATE,
        Number(v?.TAXABLE_VALUE).toFixed(2),
        Number(v?.IGST_AMOUNT).toFixed(2),
        Number(v?.CGST_AMOUNT).toFixed(2),
        Number(v?.SGST_AMOUNT).toFixed(2),
        Number(v?.INV_AMOUNT).toFixed(2),
      ]),
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTAL",
        "",
        Number(total?.quantity)?.toFixed(0),
        Number(total?.PREPAID).toFixed(0),
        Number(total?.PSV).toFixed(0),
        "",
        "",
        Number(total?.unit_price)?.toFixed(2),
        Number(total?.discount)?.toFixed(2),
        Number(total?.taxable)?.toFixed(2),
        Number(total?.igst)?.toFixed(2),
        Number(total?.cgst)?.toFixed(2),
        Number(total?.sgst)?.toFixed(2),
        Number(total?.inv_amount)?.toFixed(2),
      ],
    ];

    return data;
  };
  const table_header = [
    "INVOICE NO",
    "INVOICE DATE",
    "GODOWN NAME",
    "PRODUCT	",
    "HSN CODE",
    "GST RATE",
    "CUSTOMER	",
    "CUSTOMER GSTIN",
    "DELIVERED BY/STAFF NAME",
    "QTY	",
    "PREPAID / ONLINE",
    "SV CYLINDERS",
    "EMPTIES RECEIVED",
    "UNITS	",
    "UNIT PRICE",
    "DISCOUNT",
    "TAXABLE VALUE",
    "IGST AMOUNT",
    "CGST AMOUNT",
    "SGST AMOUNT",
    "INVOICE AMOUNT",
  ];
  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - SALES REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`,
      tableName: `${firm_name} - SALES REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `${firm_name} - SALES REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", addData?.action);
        body.append("agencyid", user_id);
        body.append("sdate", addData?.from_date);
        body.append("edate", addData?.to_date);
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        if (addData?.action == "staff") {
          body.append("staffid", addData?.staffid);
        } else if (addData?.action == "product") {
          body.append("productid", addData?.product_code);
          body.append("prod_cat", addData?.product_cat);
        } else if (addData?.action == "customer") {
          body.append("customerid", addData?.customer_id);
        }
        const response = await sales_report_api(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else {
            setPurchase_report_item_list(
              response?.data || response?.productlist
            );
            setTotalCount(response?.totalcount);
            setShowData(true);
            setAddDataPre(addData);
            setTotalData(response?.total);
          }
        } else if (!response.success) {
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      DateValidator2(
        moment(addData?.from_date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.from_date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `From Date Date cant be before Effective Date ${eff_date}`,
          `From Date Date cant be a future date`
        )
      );
    }
    if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (
      DateValidator2(
        moment(addData?.to_date).format("DD/MM/YYYY"),
        today,
        addData?.from_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.to_date).format("DD/MM/YYYY"),
          today,
          addData?.from_date,
          `To Date cant be before ${moment(addData?.from_date).format(
            "DD/MM/YYYY"
          )} `,
          `To Date cant be a future date`
        )
      );
    }
    if (!addData?.action || (addData?.action && addData?.action?.length == 0)) {
      data.push("Please Select Type of Sales");
    } else {
      if (
        addData?.action == "customer" &&
        (!addData?.customer_id ||
          (addData?.customer_id && addData?.customer_id?.length == 0))
      ) {
        data.push("Please Select Customer Name");
      } else if (
        addData?.action == "product" &&
        (!addData?.product_code ||
          (addData?.product_code && addData?.product_code?.length == 0))
      ) {
        data.push("Please Select Customer Name");
      } else if (
        addData?.action == "staff" &&
        (!addData?.staffid ||
          (addData?.staffid && addData?.staffid?.length == 0))
      ) {
        data.push("Please Select Staff Name");
      }
    }

    return data;
  };
  return (
    <div className="container ">
      <PageHeading title={"SALES REPORT"} right={<BackButton />} />
      <div className="transactionMain">
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
            type={"date"}
          />
        </div>

        <div style={{ width: "220px" }}>
          <Dropdown
            label={"Select Type Of Sales"}
            value={addData?.action}
            onChange={(e) => {
              setAddData({
                ...addData,
                action: e.target.value,
                product_code: "",
                product_cat: "",
                custProdDetails: "",
                staffid: "",
                staff_cat: "",
                customer_id: "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                <option value={"product"}>Product Wise</option>
                <option value={"staff"}>Deliverd By/ Staff Name</option>
                <option value={"customer"}>Customer Wise</option>
              </>
            }
          />
        </div>

        {addData?.action == "product" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"PRODUCT"}
              value={addData?.product_code}
              onChange={(e) => {
                const data = sales_report_list?.productlist?.filter(
                  (v) => v?.ID == e.target.value
                )?.[0];
                setAddData({
                  ...addData,
                  product_code: e.target.value,
                  product_cat: data ? data?.CAT_TYPE : "",
                  custProdDetails: data
                    ? data?.CAT_NAME == "DOMESTIC" ||
                      data?.CAT_NAME == "COMMERCIAL" ||
                      data?.CAT_NAME == "REGULATOR"
                      ? `${data?.CAT_NAME} - ${data?.CAT_DESC}`
                      : `${data?.CAT_DESC} - ${data?.PROD_BRAND} - ${data?.PROD_NAME}`
                    : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {sales_report_list?.productlist?.map((v, i) => (
                    <option value={v?.ID} key={i}>
                      {v?.CAT_NAME == "DOMESTIC" ||
                      v?.CAT_NAME == "COMMERCIAL" ||
                      v?.CAT_NAME == "REGULATOR"
                        ? `${v?.CAT_NAME} - ${v?.CAT_DESC}`
                        : `${v?.CAT_DESC} - ${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                    </option>
                  ))}
                </>
              }
            />
          </div>
        )}
        {addData?.action == "staff" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"DELIVERED BY/STAFF NAME"}
              value={addData?.staffid}
              onChange={(e) => {
                const data = sales_report_list?.customerlist?.filter(
                  (v) => v?.ID == e.target.value
                )?.[0];
                setAddData({
                  ...addData,
                  staffid: e.target.value,
                  staff_cat: data ? data?.DESIGNATION : "",
                  custProdDetails: data ? data?.EMP_NAME : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {sales_report_list?.customerlist?.map((v, i) => (
                    <option value={v?.ID} key={i}>
                      {v?.EMP_NAME}
                    </option>
                  ))}
                </>
              }
            />
          </div>
        )}

        {addData?.action == "customer" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"CUSTOMER NAME"}
              value={addData?.customer_id}
              onChange={(e) => {
                const data = transition_complete_list?.Customer?.filter(
                  (v) => v?.ID == e.target.value
                );
                setAddData({
                  ...addData,
                  customer_id: e.target.value,
                  custProdDetails:
                    data?.length > 0 ? data?.[0]?.cvo_name?.toUpperCase() : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {transition_complete_list?.Customer?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.cvo_name?.toUpperCase()}
                    </option>
                  ))}
                </>
              }
            />
          </div>
        )}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            // onClick={() => get_list(addData)}
            onClick={async () => {
              await setShowData(false);
              await setPageNo(1);
              await setEntries(5);
              get_list(addData, "main");
            }}
          />
        </div>
      </div>

      {showData && purchase_report_item_list?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>SELECTED:</span>
            <h6 className="PDS">{addDataPre?.custProdDetails}</h6>
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {purchase_report_item_list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <Table
              headData={table_header}
              body={
                <>
                  {purchase_report_item_list?.map((v, i) => (
                    <tr key={i}>
                      <td>{v?.SR_NO}</td>
                      <td>{v?.INV_DATE}</td>
                      <td>{v?.GODOWN_NAME || "-"}</td>
                      <td>
                        {v?.CAT_NAME} - {v?.CAT_DESC}
                      </td>
                      <td>{v?.HSN_CODE}</td>
                      <td>{v?.GSTP}</td>
                      <td>{v?.CVO_NAME || "NA"}</td>
                      <td>{v?.CVO_TIN || "NA"}</td>
                      <td>{v?.EMP_NAME}</td>
                      <td>{v?.QUANTITY}</td>
                      <td>{v?.PREPAID}</td>
                      <td>{v?.PSV}</td>
                      <td>{0}</td>
                      <td>{"NOS"}</td>
                      <td>{v?.UNIT_RATE}</td>
                      <td>{v?.DISC_UNIT_RATE}</td>
                      <td>{Number(v?.TAXABLE_VALUE)?.toFixed(2)}</td>
                      <td>{Number(v?.IGST_AMOUNT)?.toFixed(2)}</td>
                      <td>{Number(v?.CGST_AMOUNT)?.toFixed(2)}</td>
                      <td>{Number(v?.SGST_AMOUNT)?.toFixed(2)}</td>
                      <td>{Number(v?.INV_AMOUNT)?.toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={7} />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      TOTAL
                    </td>
                    <td />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.quantity).toFixed(0)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.PREPAID).toFixed(0)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.PSV).toFixed(0)}
                    </td>
                    <td />
                    <td />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.unit_price)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.discount)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.taxable)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.igst)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.cgst)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.sgst)?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {Number(totalData?.inv_amount)?.toFixed(2)}
                    </td>
                  </tr>
                </>
              }
            />
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      <div style={{ height: "10px" }}></div>

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default SalesReport;
