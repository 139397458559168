import React, { useEffect, useMemo, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import PageLoading from "../../../components/PageLoading";
import { BankCodeFinder, DeleteIcon, Radio } from "../../../utils/utils";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { ARBList } from "../../OtherComponent/StaticLists/staticLists";
import { Pagination2 } from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import moment from "moment";
import Table from "../../../components/table/Table";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  get_bank_master_list,
  get_customer_vender_list,
} from "../../MasterData/masterdataSlice";
import {
  get_blpg_arb_sales_list,
  get_commercial_sales_list,
  get_domestic_sales_list,
  get_sales_return_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { sales_return_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import { DropInput } from "../../../components/inputs/DropInput";
import { Alerts } from "../../../components/Alerts/Alerts";

export const SalesReturn = () => {
  const [CSClick, setCSClick] = useState(false);
  const [arbClick, setArbClick] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [DSClick, setDSClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
  };

  const { godown_address_list } = useSelector((state) => state.profile);
  const { eff_date, user_id, today } = useSelector((state) => state.other);
  const {
    return_sales_list,
    return_sales_loading,
    transition_complete_list,
    commercial_sales_list,
    domestic_sales_list,
    blpg_sales_list,
  } = useSelector((state) => state.transactions);

  const { cust_vend_list, bank_master_list, master_complete_list } =
    useSelector((state) => state.masterdata);


  useEffect(() => {
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_commercial_sales_list());
    store.dispatch(get_blpg_arb_sales_list());
    store.dispatch(get_domestic_sales_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_sales_return_list(obj));
  }, [entries, pageNo]);

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      await sales_return_api(body).then((v) => {
        setShowLoader(false);
        if (v?.success) {
          setOpen(false);
          if (return_sales_list?.length < 2) {
            setPageNo(pageNo - 1);
            store.dispatch(
              get_sales_return_list({
                page: pageNo - 1,
                limit: entries,
                setTotalCount: setTotalCount,
              })
            );
          } else {
            store.dispatch(
              get_sales_return_list({
                page: pageNo,
                limit: entries,
                setTotalCount: setTotalCount,
              })
            );
          }
          toast(v?.message);
        } else {
          setMess(v?.message);
          setOpen1(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const valiDate = () => {
    const data = [];
    if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && addDataHead?.ref_no?.length === 0)
    ) {
      data.push("Please Select Invoice Number");
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name?.length === 0)
    ) {
      data.push("Please Select COUSTOMER NAME");
    }
    if (
      !addDataHead?.payment_terms ||
      (addDataHead?.payment_terms && addDataHead?.payment_terms?.length === 0)
    ) {
      data.push("Please Select Payment Terms");
    }
    if (
      !addDataHead?.narration ||
      (addDataHead?.narration && addDataHead?.narration?.trim()?.length === 0)
    ) {
      data.push("Please Enter Narration");
    }
    if (
      godown_address_list?.length > 1 &&
      addDataHead?.godown_req &&
      (!addDataHead?.godown_id ||
        (addDataHead?.godown_id && addDataHead?.godown_id?.length === 0))
    ) {
      data.push("Please Select Godown Address");
    }

    for (let v of addData) {
      if (
        !v?.product_code ||
        (v?.product_code && v?.product_code?.length === 0)
      ) {
        data.push("Please Select PRODUCT");
      }

      if (!v?.quantity || (v?.quantity && v?.quantity?.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v?.quantity == 0) {
        data.push("Please Enter Valid Quantity");
      }

      if (!v?.net_weight || (v?.net_weight && v?.net_weight?.length === 0)) {
        data.push("Please Enter Net Weight");
      } else if (v?.net_weight == 0) {
        data.push("Please Enter Valid Net Weight");
      }
      if (v?.quantity && v?.product_code && v?.net_weight) {
        if (
          !v?.taxable_value ||
          (v?.taxable_value && v?.taxable_value?.length === 0) ||
          !v?.igst_amount ||
          (v?.igst_amount && v?.igst_amount?.length === 0) ||
          !v?.sgst_amount ||
          (v?.sgst_amount && v?.sgst_amount?.length === 0) ||
          !v?.cgst_amount ||
          (v?.cgst_amount && v?.cgst_amount?.length === 0) ||
          !v?.amount ||
          (v?.amount && v?.amount?.length === 0)
        ) {
          data.push("Please Click on Calculate First");
        }
      }

      if (
        addDataHead?.payment_terms == "2" &&
        (!v?.bank_account || (v?.bank_account && v?.bank_account?.length === 0))
      ) {
        data.push("Please Enter Bank Account");
      }
    }
    if (
      !addDataHead?.total_amount ||
      (addDataHead?.total_amount &&
        addDataHead?.total_amount?.trim()?.length === 0)
    ) {
      data.push("Please Click on Calculate first");
    }
    return data;
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v?.product_code && v?.product_code?.length == 0) ||
          !v?.p_gst ||
          (v?.p_gst && v?.p_gst?.length == 0) ||
          !v?.quantity ||
          (v?.quantity && v?.quantity?.length == 0) ||
          v?.quantity == 0 ||
          !v?.net_weight ||
          (v?.net_weight && v?.net_weight?.length == 0) ||
          !v?.unit_rate ||
          (v?.unit_rate && v?.unit_rate?.length == 0) ||
          !v?.taxable_value ||
          (v?.taxable_value && v?.taxable_value?.length == 0) ||
          !v?.igst_amount ||
          (v?.igst_amount && v?.igst_amount?.length == 0) ||
          !v?.sgst_amount ||
          (v?.sgst_amount && v?.sgst_amount?.length == 0) ||
          !v?.cgst_amount ||
          (v?.cgst_amount && v?.cgst_amount?.length == 0) ||
          !v?.amount ||
          (v?.amount && v?.amount?.length == 0)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const add_items = async () => {
    const data = valiDate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("inv_ref_no", addDataHead?.ref_no);
        body.append("inv_date", addDataHead?.inv_date);
        body.append("customer_id", addDataHead?.customer_name);
        body.append("payment_terms", addDataHead?.payment_terms);
        body.append("inv_amt", addDataHead?.total_amount);
        body.append("narration", addDataHead?.narration);
        if (addDataHead?.godown_req) {
          body.append(
            "godown_id",
            addDataHead?.godown_id || godown_address_list?.[0]?.ID || 0
          );
        }
        addData.forEach((item) => {
          if (item) {
            body.append("pcode[]", item?.product_code);
            body.append("gstp[]", item?.p_gst);
            body.append("quantity[]", item?.quantity);
            body.append("unit_rate[]", item?.unit_rate);
            body.append("net_weight[]", item?.net_weight);
            body.append("bank_id[]", item?.bank_account);
            body.append("amount[]", item?.taxable_value);
            body.append("igst[]", item?.igst_amount);
            body.append("sgst[]", item?.sgst_amount);
            body.append("cgst[]", item?.cgst_amount);
            body.append("aamount[]", item?.amount);
          }
        });
        const response = await sales_return_api(body);
        setShowLoader(false);
        if (response.success) {
          setIsAdd(false);
          setAddDataHead({});
          setAddData([]);
          store.dispatch(get_sales_return_list(obj));
          toast.success(response?.message);
        } else if (!response?.success) {
          setMess(response?.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const calculate = () => {
    if (!addDataHead?.ref_no) {
      setMess("Please select Invoice Number");
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v?.product_code && v?.product_code.length == 0) ||
          !v?.unit_rate ||
          (v?.unit_rate && v?.unit_rate.length == 0) ||
          !v?.quantity ||
          (v?.quantity && v?.quantity.length == 0)
      )
    ) {
      setMess(`Please Enter UNIT RATE. or \n Please Enter QUANTITY.`);
      setOpen1(true);
      return;
    }
    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data?.unit_rate);
      const quantity = Number(data?.quantity);
      const p_gst = Number(data?.p_gst);

      const tax_amount = unit_rate * quantity;
      const gst_amount = tax_amount * (p_gst / 100);
      const amount = tax_amount + gst_amount;

      setAddData((prevData) => {
        return prevData?.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              taxable_value: tax_amount.toFixed(2),
              igst_amount:
                addDataHead?.c_type == "isp"
                  ? Number(gst_amount).toFixed(2)
                  : "0.00",
              sgst_amount:
                addDataHead?.c_type == "lp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : "0.00",
              cgst_amount:
                addDataHead?.c_type == "lp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : "0.00",
              amount: amount.toFixed(2),
            };
          }
          return item;
        });
      });
      total = total + amount;
    });
    setAddDataHead({ ...addDataHead, total_amount: Number(total).toFixed(2) });
  };
  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={"SALES RETURN INVOICE ENTRY"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />
      {return_sales_loading ? (
        <PageLoading />
      ) : return_sales_list?.length == 0 ? (
        <NoData />
      ) : (
        <>
          <Pagination2
            top={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {return_sales_list?.map((v, i) => (
              <div className="card" key={i}>
                <div className="card-heading">
                  {v?.CVO_NAME ? `${v?.CVO_NAME} -` : ""} {v?.SR_NO}
                </div>
                <RowTxt title={"Invoice Ref No."} value={v?.INV_REF_NO} />
                <RowTxt
                  title={"Invoice Date"}
                  value={moment(v?.INV_DATE).format("DD-MMM-YYYY")}
                />
                <RowTxt title={"Total Amount"} value={v?.INV_AMT} />
                <RowTxt title={"Narration"} value={v?.NARRATION} />
                <div className="df jcsb mt10">
                  <DeleteIcon
                    onClick={() => {
                      setId(v?.ID);
                      setOpen(true);
                    }}
                  />
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <Pagination2
        bottom={true}
        totalCount={totalCount}
        SerchInput={SerchInput}
        setSearchInput={setSearchInput}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      />
      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
            setAddData([]);
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button title={"DS"} back onClick={() => setDSClick(!DSClick)} />
              <Button title={"CS"} back onClick={() => setCSClick(!CSClick)} />
              <Button
                title={"ARB"}
                back
                onClick={() => setArbClick(!arbClick)}
              />
            </div>
          }
          title={"SALES RETURN INVOICE ENTRY"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Dropdown
                  width2=""
                  label={"CUSTOMER NAME"}
                  onChange={(e) => {
                    const data = transition_complete_list?.Customer?.filter(
                      (v) => v?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      customer_name: e.target.value,
                      c_type:
                        data?.length > 0
                          ? data[0]?.connection_type
                          : e.target.value == "1"
                          ? "lp"
                          : "",
                      dis: data?.length > 0 ? true : false,
                      payment_terms: data?.length > 0 ? "3" : "",
                      ref_no: "",
                      inv_date: "",
                    });
                    addData?.forEach((item, index) => {
                      setAddData((prevData) =>
                        prevData.map((v, i) =>
                          i === index
                            ? {
                                ...v,
                                bank_account:
                                  e.target.value == "1" ? "" : v?.bank_account,
                              }
                            : v
                        )
                      );
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH SALES</option>
                      {transition_complete_list?.Customer?.filter(
                        (v) => v?.cvo_name != "UJWALA"
                      ).map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.cvo_name}
                        </option>
                      ))}
                    </>
                  }
                />
                <Input
                  label={"INVOICE DATE"}
                  type={"date"}
                  value={addDataHead.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                      });
                    }
                  }}
                />

                <DropInput
                  label={"INVOICE REF NUMBER"}
                  id="invoice"
                  placeholder={"INVOICE REF NUMBER"}
                  value={addDataHead?.ref_no}
                  onChange={(e) => {
                    const data = [
                      ...domestic_sales_list,
                      ...commercial_sales_list,
                      ...blpg_sales_list,
                    ]?.filter((v) => v?.SR_NO == e.target.value);
                    setAddDataHead({
                      ...addDataHead,
                      ref_no: e.target.value,
                      godown_id:
                        data?.length > 0 ? data[0]?.DISPATCH_GODOWN : "",
                      godown_req:
                        data?.length > 0
                          ? data?.[0]?.products?.[0]?.CAT_NAME == "DOMESTIC" ||
                            data?.[0]?.products?.[0]?.CAT_NAME ==
                              "COMMERCIAL" ||
                            data?.[0]?.products?.[0]?.CAT_NAME == "REGULATOR"
                          : false,
                    });
                  }}
                  list={[
                    ...domestic_sales_list,
                    ...commercial_sales_list,
                    ...blpg_sales_list,
                  ]
                    ?.filter(
                      (v, i) =>
                        v?.CUSTOMER_ID == addDataHead?.customer_name ||
                        (addDataHead?.customer_name == "1" &&
                          v?.CUSTOMER_ID == "0")
                    )
                    ?.map((v, i) => (
                      <option key={i} value={v?.SR_NO}>
                        {v?.SR_NO}
                      </option>
                    ))}
                />
                <Input
                  label={"TOTAL AMOUNT"}
                  placeholder={"TOTAL AMOUNT"}
                  value={addDataHead.total_amount}
                  disabled={true}
                />
                <Dropdown
                  width2=""
                  label={"PAYMENT TERMS"}
                  value={addDataHead.payment_terms}
                  disabled={addDataHead?.dis}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      payment_terms: e.target.value,
                    });
                    addData?.forEach((item, index) => {
                      setAddData((prevData) =>
                        prevData.map((v, i) =>
                          i === index
                            ? {
                                ...v,
                                bank_account:
                                  e.target.value == "1" ? "" : v?.bank_account,
                              }
                            : v
                        )
                      );
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH</option>
                      <option value={"2"}>ONLINE</option>
                      {/* <option value={"3"}>CREDIT</option> */}
                    </>
                  }
                />
                <Input
                  label={"NARRATION"}
                  placeholder={"NARRATION"}
                  value={addDataHead.narration}
                  onChange={(e) =>
                    setAddDataHead({
                      ...addDataHead,
                      narration: e.target.value,
                    })
                  }
                />
                {godown_address_list?.length > 1 && addDataHead?.godown_req && (
                  <Dropdown
                    width2=""
                    label={"RETURN GODOWN"}
                    value={addDataHead?.godown_id}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        godown_id: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {godown_address_list?.map((s, i) => (
                          <option
                            disabled={addDataHead?.godown_id != s?.ID}
                            key={i}
                            value={s?.ID}
                          >
                            {s?.UNIT_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                )}
              </div>
              <div style={{ marginTop: "1rem" }} className="flex aic gap10">
                <div
                  style={{ width: "50%", marginTop: "1.5rem" }}
                  className="flex aic gap10 mt10"
                >
                  <RadioBtn label={"SALE TYPE"} />
                  <Radio
                    disabled={addDataHead?.dis}
                    title={"LOCAL SALE"}
                    show={addDataHead.c_type == "lp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "lp" })
                    }
                  />
                  <Radio
                    disabled={addDataHead?.dis}
                    title={"INTER-STATE SALE"}
                    show={addDataHead.c_type == "isp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "isp" })
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT",
                    "GST %	",
                    "PURCHASE QTY",
                    "RETURN QTY",
                    "NET WEIGHT",
                    "UNIT PRICE",
                    "TAXABLE AMOUNT",
                    "IGST AMOUNT",
                    "CGST AMOUNT",
                    "SGST AMOUNT",
                    "AMOUNT",
                    "BANK ACCOUNT",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        <Dropdown
                          options={
                            <>
                              <option value={""}>SELECT</option>
                              {[
                                ...domestic_sales_list,
                                ...commercial_sales_list,
                                ...blpg_sales_list,
                              ]
                                ?.filter(
                                  (v) => v?.SR_NO == addDataHead?.ref_no
                                )?.[0]
                                ?.products?.map((v, i) =>
                                  v?.CAT_NAME == "DOMESTIC" ||
                                  v?.CAT_NAME == "COMMERCIAL" ||
                                  v?.CAT_NAME == "REGULATOR" ? (
                                    <option
                                      disabled={
                                        addData?.filter(
                                          (d) => d?.product_code == v?.CODE
                                        )?.length > 0
                                      }
                                      key={i}
                                      value={v?.CODE}
                                    >
                                      {v?.CAT_NAME} - {v?.CAT_DESC}
                                    </option>
                                  ) : (
                                    <option
                                      disabled={
                                        addData?.filter(
                                          (d) => d?.product_code == v?.CODE
                                        )?.length > 0
                                      }
                                      key={i}
                                      value={v?.CODE}
                                    >
                                      {v?.CAT_DESC} - {v?.PROD_BRAND} -{" "}
                                      {v?.PROD_NAME}
                                    </option>
                                  )
                                )}
                            </>
                          }
                          onChange={(e) => {
                            const data = [
                              ...domestic_sales_list,
                              ...commercial_sales_list,
                              ...blpg_sales_list,
                            ]
                              ?.filter(
                                (v) => v?.SR_NO == addDataHead?.ref_no
                              )?.[0]
                              ?.products?.filter(
                                (v, i) => v?.CODE == e.target.value
                              )?.[0];

                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      product_code: e.target.value,
                                      p_gst: data ? data?.GSTP : "",
                                      pur_quantity: data ? data?.QUANTITY : "",
                                      unit_rate: data ? data?.UNIT_RATE : "",
                                    }
                                  : { ...v }
                              )
                            );
                          }}
                          value={val.product_code}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.p_gst}
                          placeholder={"GST %	"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.pur_quantity}
                          placeholder={"Purchase Quantity"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.quantity}
                          placeholder={"Quantity"}
                          onChange={(e) => {
                            // setAddData((pre) =>
                            //   pre.map((v, i) =>
                            //     i == ind
                            //       ? {
                            //           ...v,
                            //           quantity: e.target.value,
                            //         }
                            //       : { ...v }
                            //   )
                            // );
                            const inputValue = e.target.value;
                            if (
                              (inputValue?.length <= 4 &&
                                /^\d{0,4}$/.test(inputValue)) ||
                              inputValue === "0" ||
                              !val?.quantity
                            ) {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        quantity: /^\d{0,4}$/.test(inputValue)
                                          ? inputValue
                                          : "",
                                      }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.net_weight}
                          placeholder={"NET WEIGHT"}
                          onChange={(e) => {
                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      net_weight: e.target.value,
                                    }
                                  : { ...v }
                              )
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.unit_rate}
                          placeholder={"UNIT PRICE"}
                          disabled={true}
                        />
                      </td>

                      <td>
                        <Input
                          value={val?.taxable_value}
                          placeholder={"TAXABLE VALUE"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.igst_amount}
                            placeholder={"IGST AMOUNT"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        <Input
                          value={val?.sgst_amount}
                          placeholder={"SGST AMOUNT"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.cgst_amount}
                          placeholder={"CGST AMOUNT"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Input
                          value={val?.amount}
                          placeholder={"Sale Amount"}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Dropdown
                          disabled={!(addDataHead?.payment_terms == "2")}
                          options={
                            <>
                              <option value={""}>SELECT</option>
                              {bank_master_list
                                ?.filter(
                                  (v, i) => v?.BANK_CODE != "STAR ACCOUNT"
                                )
                                ?.filter(
                                  (v, i) => v?.BANK_CODE != "CASH ACCOUNT"
                                )
                                // ?.filter((v, i) => v?.BANK_CODE != "OVER DRAFT")
                                ?.filter(
                                  (v, i) => v?.BANK_CODE != "TAR ACCOUNT"
                                )
                                ?.filter((v, i) => v?.BANK_CODE != "LOAN")
                                ?.map((v, i) => (
                                  <option key={i} value={v?.ID}>
                                    {bankCode(v?.BANK_CODE)} -
                                    {v?.BANK_ACC_NO == "NA"
                                      ? user_id
                                      : v?.BANK_ACC_NO}
                                  </option>
                                ))}
                            </>
                          }
                          onChange={(e) =>
                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      bank_account: e.target.value,
                                    }
                                  : { ...v }
                              )
                            )
                          }
                          value={val.bank_account}
                        />
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                total_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />

                {/* <Button title={"FETCH GST"} back /> */}
                <Button title={"CALCULATE"} onClick={calculate} back />
                <SaveButton onClick={add_items} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {DSClick && <ARBList DSClick={DSClick} setDSClick={setDSClick} />}
      {CSClick && <ARBList CSClick={CSClick} setCSClick={setCSClick} />}
      {arbClick && <ARBList arbClick={arbClick} setArbClick={setArbClick} />}

      {openView && (
        <Modal
          isOpen={openView}
          title={"SALES RETURNS INVOICE ENTRY"}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                // nodata={custVendList.length != 0 && !showLoading ? false : true}
                headData={[
                  "PRODUCT",
                  "BANK	ACCOUNT",
                  "GST%",
                  "return quantity",
                  "NET WEIGHT",
                  "UNIT PRICE",
                  "TAXABLE VALUE",
                  "IGST AMOUNT",
                  "SGST AMOUNT",
                  "CGST AMOUNT",
                  "TOTAL AMOUNT	",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>{`${v?.CAT_NAME} - ${v?.PRODUCT_NAME}`}</td>
                        <td>{v?.BANK_ID == 0 ? "NA" : v?.BANK_ID}</td>
                        <td>{v?.GSTP}</td>
                        <td>{v?.RTN_QTY}</td>
                        <td>{Number(v?.NET_WEIGHT).toFixed(2)}</td>
                        <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                        <td>{v?.AMOUNT}</td>
                        <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.AAMOUNT).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.AAMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {showLoader && <Loader />}
    </div>
  );
};
