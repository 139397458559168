import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeading from "../../components/PageHeading";
import RowTxt from "../../components/RowTxt";
import { motion } from "framer-motion";
import Button from "../../components/btn/Button";
import { MdAdd } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { HiOutlineArrowRight } from "react-icons/hi";
import Table from "../../components/table/Table";
import Modal from "../../components/modal/Modal";
import Input from "../../components/inputs/Input";
import Dropdown from "../../components/inputs/Dropdown";
import { BankCodeFinder, DeleteIcon, Radio, RadioNP } from "../../utils/utils";
import store from "../../redux/store";
import moment from "moment";
import PageLoading from "../../components/PageLoading";
import NoData from "../../components/NoData";
import { Pagination2 } from "../../components/pagination/pagination";
import { useSelector } from "react-redux";
import { get_debit_note_list } from "./cashBankSlice";
import SmallModal from "../../components/modal/SmallModal";
import { debit_note_API } from "../../utils/apis";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { creditNoteResons } from "../../utils/data";
import RadioBtn from "../../components/radio/RadioBtn";
import { DropInput } from "../../components/inputs/DropInput";
import { DateValidator2 } from "../../utils/validation";
import { BackButton, SaveButton } from "../../components/btn/Buttons";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
import {
  get_arb_list,
  get_bank_master_list,
  get_equipment_list,
} from "../MasterData/masterdataSlice";
import { Alerts } from "../../components/Alerts/Alerts";
import { TaxInvoice } from "../OtherComponent/TaxInvoice/TaxInvoice";
import ReactPrint from "../../components/ReactPrint";

const tax_invoice_table_header = [
  "SR. NO.",
  "TAXABLE AMOUNT",
  "GST %",
  "IGST AMOUNT",
  "CGST AMOUNT",
  "SGST AMOUNT",
  "TOTAL AMOUNT",
  "REASON",
  "NARRATION",
];
const tax_invoice_table_header2 = ["SR. NO.", "TOTAL AMOUNT", "REASON"];

const DebitNote = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [addDataHead, setAddDataHead] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [entries, setEntries] = useState("5");
  const [viewItem, setViewItem] = useState({});
  const [openView, setOpenView] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [list_inv, setList_inv] = useState([]);
  const { debit_node_loading, debit_node_list } = useSelector(
    (state) => state.cashbanks
  );
  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );

  const { equipment_list, arb_list, bank_master_list } = useSelector(
    (state) => state.masterdata
  );
  const { day_end_date } = useSelector((state) => state.profile);
  const { user_id, today } = useSelector((state) => state.other);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_equipment_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_bank_master_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_debit_note_list(obj));
  }, [pageNo, entries]);

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      const response = await debit_note_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          debit_node_list?.length < 2 && pageNo != 1 ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_debit_note_list({
            pageNo:
              debit_node_list?.length < 2 && pageNo != 1 ? pageNo - 1 : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (type) => {
    const data = [];
    if (
      !addDataHead?.date ||
      (addDataHead?.date && addDataHead?.date?.length != 10)
    ) {
      data.push("Please Enter Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Debit Note Date cant be befor as a day end date ${day_end_date}`,
          `Debit Note Date cant be a future date`
        )
      );
    }
    if (
      !addDataHead?.credit_type ||
      (addDataHead?.credit_type && addDataHead?.credit_type?.length == 0)
    ) {
      data.push("Please Select Debit Note Type");
    }
    if (
      !addDataHead?.gst_type ||
      (addDataHead?.gst_type && addDataHead?.gst_type?.length == 0)
    ) {
      data.push("Please Select GST Reg");
    } else if (addDataHead?.gst_type != "2") {
      if (
        !type &&
        (!addDataHead?.ref_number_id ||
          (addDataHead?.ref_number_id &&
            addDataHead?.ref_number_id?.length == 0))
      ) {
        data.push("Please Enter Reference Number");
      }
    }

    if (
      !addDataHead?.cust_vend_name ||
      (addDataHead?.cust_vend_name && addDataHead?.cust_vend_name?.length == 0)
    ) {
      data.push("Please Enter Customer Vendor Name");
    }
    if (
      !addDataHead?.c_type ||
      (addDataHead?.c_type && addDataHead?.c_type?.length == 0)
    ) {
      data.push("Please Select Local and Enter State");
    }

    // if (
    //   !addDataHead?.product_code ||
    //   (addDataHead?.product_code && addDataHead?.product_code?.length == 0)
    // ) {
    //   data.push("Please Select Product");
    // }
    if (addDataHead?.gst_type != "2") {
      if (
        !addDataHead?.gst ||
        (addDataHead?.gst && addDataHead?.gst?.length == 0)
      ) {
        data.push("Please Select GST %");
      }
      if (
        !addDataHead?.tax_amount ||
        (addDataHead?.tax_amount && addDataHead?.tax_amount?.length == 0)
      ) {
        data.push("Please Enter Taxable Value ");
      } else if (addDataHead?.tax_amount < 0) {
        data.push("Taxable Value must be greaterthen zero");
      }
    }

    if (!type) {
      if (addDataHead?.gst_type != "2") {
        if (
          !addDataHead?.total_amount ||
          (addDataHead?.total_amount && addDataHead?.total_amount?.length == 0)
        ) {
          data.push("Please Click on Calculate");
        }
        // if (
        //   !addDataHead?.bank_account ||
        //   (addDataHead?.bank_account && addDataHead?.bank_account?.length == 0)
        // ) {
        //   data.push("Please Select Bank Account");
        // }

        if (
          !addDataHead?.remark ||
          (addDataHead?.remark && addDataHead?.remark?.length == 0)
        ) {
          data.push("Please Enter Remark");
        }
      } else if (addDataHead?.gst_type == "2") {
        if (
          !addDataHead?.total_amount ||
          (addDataHead?.total_amount && addDataHead?.total_amount?.length == 0)
        ) {
          data.push("Please Enter Total Amount");
        }
      }
      if (
        !addDataHead?.reason ||
        (addDataHead?.reason && addDataHead?.reason?.length == 0)
      ) {
        data.push("Please Select Reason");
      }
    }

    return data;
  };

  const calculate = () => {
    const data = validate("calculate");
    if (data?.length == 0) {
      const tax_amount = Number(addDataHead?.tax_amount);
      const gst = Number(addDataHead?.gst);

      const gst_amount = (tax_amount * gst) / 100;
      const total = tax_amount + gst_amount;

      setAddDataHead({
        ...addDataHead,
        total_amount: total,
        gst_amount: gst_amount,
        igst: addDataHead?.c_type == "isp" ? gst_amount : "0.00",
        sgst: addDataHead?.c_type != "isp" ? gst_amount / 2 : "0.00",
        cgst: addDataHead?.c_type != "isp" ? gst_amount / 2 : "0.00",
      });
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const add_items = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        // create(type,DEBIT_AMOUNT,NOTE_DATE,DEBIT_NOTE_TYPE,REF_NO,GSTP,TAXABLE_AMOUNT,CGST_AMOUNT,
        //   SGST_AMOUNT,IGST_AMOUNT,BANK_ID,NREASONS,CVO_ID,CVO_NAME,agencyid)
        setShowLoader(true);
        const body = new FormData();

        body.append("action", "create");
        body.append("type", addDataHead?.gst_type);
        body.append("CVO_REG", addDataHead?.gst_type);
        body.append("agencyid", user_id);
        body.append("NOTE_DATE", addDataHead?.date);
        body.append("DEBIT_NOTE_TYPE", addDataHead?.credit_type);
        body.append(
          "REF_NO",
          addDataHead?.gst_type == "1" ? addDataHead?.ref_number_id : ""
        );
        body.append("CVO_ID", addDataHead?.cust_vend_id);
        body.append("CVO_NAME", addDataHead?.cust_vend_name);
        body.append(
          "GSTP",
          addDataHead?.gst_type == "1" ? addDataHead?.gst : ""
        );
        body.append(
          "TAXABLE_AMOUNT",
          addDataHead?.gst_type == "1" ? addDataHead?.tax_amount : ""
        );
        body.append(
          "CGST_AMOUNT",
          addDataHead?.gst_type == "1" ? addDataHead?.cgst : ""
        );
        body.append(
          "SGST_AMOUNT",
          addDataHead?.gst_type == "1" ? addDataHead?.sgst : ""
        );
        body.append(
          "IGST_AMOUNT",
          addDataHead?.gst_type == "1" ? addDataHead?.igst : ""
        );
        body.append("DEBIT_AMOUNT", addDataHead?.total_amount);
        // body.append(
        //   "BANK_ID",
        //   addDataHead?.gst_type == "1" ? addDataHead?.bank_account : ""
        // );
        body.append("NREASONS", addDataHead?.reason);
        body.append(
          "REMARKS",
          addDataHead?.gst_type == "1" ? addDataHead?.remark : ""
        );
        const response = await debit_note_API(body);
        setShowLoader(false);
        if (response.success) {
          store.dispatch(get_debit_note_list(obj));
          setIsAdd(false);
          toast(response.message);
          setAddDataHead({});
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  // const bankCode = useMemo(() => {
  //   return (data) => BankCodeFinder(data);
  // }, [bank_master_list]);

  // invoicenumber(agencyid,CVO_ID,date)

  // const validate_fe

  const fetch_invoices_ref = async (date, cust_id) => {
    if (
      !cust_id ||
      (cust_id && cust_id?.length == 0) ||
      !date ||
      (date && date?.length != 10)
    ) {
      return;
    }
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "invoicenumber");
      body.append("agencyid", user_id);
      body.append("CVO_ID", cust_id);
      body.append("date", date);
      const response = await debit_note_API(body);
      setShowLoader(false);
      if (response.success) {
        setList_inv(response?.invoice_ref_number);
      } else if (!response.success) {
        setMess(response.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log("error ==", error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"DEBIT NOTE(S)"}
        right={
          <>
            <Button
              title={"ADD"}
              icon={<MdAdd size={20} color="#fff" />}
              onClick={() => setIsAdd(true)}
            />
            <BackButton />
          </>
        }
      />

      {debit_node_loading ? (
        <PageLoading />
      ) : debit_node_list?.length == 0 ? (
        <NoData />
      ) : (
        <>
          <Pagination2
            top={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {debit_node_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.CUSTOMER_VENDOR_NAME || "NA"}{" "}
                  </div>
                  <RowTxt
                    title={"Debit Note No."}
                    value={
                      v?.DEBIT_NOTE_TYPE == "2" ? (
                        <ReactPrint
                          num={v?.SID}
                          documentTitle={`${
                            v?.CUSTOMER_VENDOR_NAME || v?.CVO_NAME
                          }-${v?.SID}`}
                          v={v}
                          previewRef={previewRef}
                          setViewItem={setViewItem}
                        />
                      ) : (
                        v?.SID
                      )
                    }
                  />
                  {v?.REF_NO && (
                    <RowTxt title={"Debit Referance No."} value={v?.REF_NO} />
                  )}
                  <RowTxt title={"E-Invoice Gen"} value={v?.SID} />

                  <RowTxt
                    title={"Debit Note Date"}
                    value={moment(v?.NOTE_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt
                    title={"Debit Note Type"}
                    value={
                      v?.DEBIT_NOTE_TYPE == "1"
                        ? "RECEIVE DEBIT NOTE"
                        : v?.DEBIT_NOTE_TYPE == "2"
                        ? "ISSUE DEBIT NOTE"
                        : ""
                    }
                  />
                  <RowTxt
                    title={"GST Reg. Type"}
                    value={
                      v?.CVO_REG == "2"
                        ? "NON GST CN"
                        : v?.CVO_REG == "1"
                        ? "GST CN"
                        : ""
                    }
                  />

                  <RowTxt title={"Debit Note Amt."} value={v?.DEBIT_AMOUNT} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <DeleteIcon
                    onClick={() => {
                      setOpen(true);
                      setDeleteId(v?.ID);
                    }}
                  />

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    onClick={() => {
                      setViewItem(v);
                      setOpenView(true);
                    }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
            bottom={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      )}
      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button title={"CP"} back />
              <Button title={"ARBP"} back />
              <Button title={"DS"} back />
              <Button title={"CS"} back />
              <Button title={"ARB"} back />
            </div>
          }
          title={"DEBIT NOTE(S)"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label="DEBIT NOTE DATE"
                  type={"date"}
                  value={addDataHead?.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({ ...addDataHead, date: e.target.value });
                    }
                  }}
                />

                <Dropdown
                  width2=""
                  label="DEBIT NOTE TYPE"
                  value={addDataHead?.credit_type}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      credit_type: e.target.value,
                      cust_vend_name: "",
                      cust_vend_id: "",
                      c_type: "",
                      dis: false,
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      <option value="1">RECEIVE DEBIT NOTE</option>
                      <option value="2">ISSUE DEBIT NOTE</option>
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  label="GST REG. Type"
                  value={addDataHead?.gst_type}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      gst_type: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      <option value="1">GST CN</option>
                      <option value="2">NON GST CN</option>
                    </>
                  }
                />

                <DropInput
                  label={"VENDOR/CUSTOMER"}
                  id="custvend"
                  placeholder={"VENDOR/CUSTOMER"}
                  value={addDataHead?.cust_vend_name}
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Customer,
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                    ]?.filter((v) => v?.ID == e.target.value);
                    setAddDataHead({
                      ...addDataHead,
                      cust_vend_name: data[0]?.cvo_name || e.target.value,
                      cust_vend_id: data?.length == 0 ? 0 : e.target.value,
                      c_type:
                        data?.length > 0 ? data?.[0]?.connection_type : "",
                      dis: data?.length > 0 ? true : false,
                      igst: "",
                      cgst: "",
                      sgst: "",
                      total_amount: "",
                    });
                    if (data?.length > 0) {
                      fetch_invoices_ref(addDataHead?.inv_date, e.target.value);
                    }
                  }}
                  list={(addDataHead?.credit_type == "2"
                    ? [
                        ...transition_complete_list?.Customer,
                        ...transition_complete_list?.OMC,
                      ]
                    : addDataHead?.credit_type == "1"
                    ? [
                        ...transition_complete_list?.Vendor,
                        ...transition_complete_list?.OMC,
                      ]
                    : []
                  )
                    ?.filter(
                      (v, i) =>
                        (v?.IS_GST_REG == "YES" &&
                          addDataHead?.gst_type == "1") ||
                        (v?.IS_GST_REG == "NO" && addDataHead?.gst_type == "2")
                    )
                    ?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                />

                {addDataHead?.gst_type != "2" && (
                  <>
                    <Input
                      label={"INVOICE DATE"}
                      type={"date"}
                      value={addDataHead?.inv_date}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setAddDataHead({
                            ...addDataHead,
                            inv_date: e.target.value,
                          });
                        }
                        if (e.target.value.length == 10) {
                          fetch_invoices_ref(
                            e.target.value,
                            addDataHead?.cust_vend_id
                          );
                        }
                      }}
                    />
                    {/* <Input
                      label="REFERANCE INV NUMBER"
                      placeholder={"Referance Inv Number"}
                      value={addDataHead?.ref_number}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          ref_number: e.target.value,
                        });
                      }}
                    /> */}
                    <DropInput
                      label={"REFERANCE INV NUMBER"}
                      id="invoiceid"
                      placeholder={"REFERANCE INV NUMBER"}
                      value={addDataHead?.ref_number}
                      onChange={(e) => {
                        const data = list_inv?.filter(
                          (v, i) => v?.INVOICE_NUMBER == e.target.value
                        );
                        setAddDataHead({
                          ...addDataHead,
                          ref_number_id: data?.length > 0 ? e.target.value : "",
                          ref_number: e.target.value,
                        });
                      }}
                      list={list_inv?.map((v, i) => (
                        <option key={i} value={v?.INVOICE_NUMBER}>
                          {v?.INVOICE_NUMBER?.toUpperCase()}
                        </option>
                      ))}
                    />
                  </>
                )}
              </div>
              <div className="flex aic gap10 mt10">
                <RadioBtn label={"LOCAL/INTER-STATE"} />
                <Radio
                  title={"LOCAL"}
                  disabled={addDataHead?.dis}
                  show={addDataHead.c_type == "lp" ? true : false}
                  setShow={() =>
                    setAddDataHead({
                      ...addDataHead,
                      c_type: "lp",
                      igst: "",
                      cgst: "",
                      sgst: "",
                      total_amount: "",
                    })
                  }
                />
                <Radio
                  disabled={addDataHead?.dis}
                  title={"INTER-STATE"}
                  show={addDataHead.c_type == "isp" ? true : false}
                  setShow={() =>
                    setAddDataHead({
                      ...addDataHead,
                      c_type: "isp",
                      igst: "",
                      cgst: "",
                      sgst: "",
                      total_amount: "",
                    })
                  }
                />
              </div>
              <Table
                mt={5}
                headData={
                  addDataHead?.gst_type == "2"
                    ? ["TOTAL AMOUNT", "REASON"]
                    : [
                        "GST %	",
                        "TAXABLE AMOUNT",
                        "IGST AMOUNT",
                        "CGST AMOUNT",
                        "SGST AMOUNT",
                        "TOTAL AMOUNT",
                        // "CREDIT/DEBIT TO (BANK ACCOUNT)",
                        "REASON",
                        "Narration",
                      ]
                }
                body={
                  <tr>
                    {addDataHead?.gst_type != "2" && (
                      <>
                        <td>
                          <Dropdown
                            placeholder={"GST%"}
                            value={addDataHead?.gst}
                            options={
                              <>
                                <option value="">SELECT</option>
                                <option value="0">0</option>
                                <option value="5">5</option>
                                <option value="12">12</option>
                                <option value="18">18</option>
                                <option value="28">28</option>
                              </>
                            }
                            onChange={(e) => {
                              setAddDataHead({
                                ...addDataHead,
                                gst: e.target.value,
                                igst: "",
                                cgst: "",
                                sgst: "",
                                total_amount: "",
                              });
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"TAXABLE AMOUNT"}
                            value={addDataHead?.tax_amount}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.tax_amount
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  tax_amount:
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"IGST AMOUNT"}
                            value={addDataHead?.igst}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"CGST AMOUNT"}
                            value={addDataHead?.cgst}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"SGST AMOUNT"}
                            value={addDataHead?.sgst}
                            disabled={true}
                          />
                        </td>
                      </>
                    )}
                    <td>
                      <Input
                        placeholder={"TOTAL AMOUNT"}
                        value={addDataHead?.total_amount}
                        disabled={addDataHead?.gst_type == "1"}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (
                            (inputValue?.length <= 10 &&
                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                inputValue
                              )) ||
                            inputValue == 0 ||
                            !addDataHead?.total_amount
                          ) {
                            setAddDataHead({
                              ...addDataHead,
                              total_amount:
                                /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                  inputValue
                                )
                                  ? inputValue
                                  : "",
                            });
                          }
                        }}
                      />
                    </td>

                    {/* {addDataHead?.gst_type != "2" && (
                      <td>
                        <Dropdown
                          options={
                            <>
                              <option value="">SELECT</option>
                              {bank_master_list
                                ?.filter(
                                  (v, i) => v?.BANK_CODE != "CASH ACCOUNT"
                                )
                                ?.map((v, i) => (
                                  <option key={i} value={v?.ID}>
                                    {bankCode(v?.BANK_CODE)} -
                                    {v?.BANK_ACC_NO == "NA"
                                      ? user_id
                                      : v?.BANK_ACC_NO}
                                  </option>
                                ))}
                            </>
                          }
                          value={addDataHead?.bank_account}
                          onChange={(e) => {
                            setAddDataHead({
                              ...addDataHead,
                              bank_account: e.target.value,
                            });
                          }}
                        />
                      </td>
                    )} */}
                    <td>
                      <Dropdown
                        options={
                          <>
                            <option value="">SELECT</option>
                            {creditNoteResons?.map((v, i) => (
                              <option key={i} value={v?.id}>
                                {v?.res}
                              </option>
                            ))}
                          </>
                        }
                        value={addDataHead?.reason}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            reason: e.target.value,
                          });
                        }}
                      />
                    </td>
                    {addDataHead?.gst_type != 2 && (
                      <td>
                        <Input
                          placeholder={"Narration"}
                          value={addDataHead?.remark}
                          onChange={(e) => {
                            setAddDataHead({
                              ...addDataHead,
                              remark: e.target.value,
                            });
                          }}
                        />
                      </td>
                    )}
                  </tr>
                }
              />
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                {addDataHead?.gst_type != "2" && (
                  <Button title={"CALCULATE"} onClick={calculate} />
                )}
                <SaveButton onClick={add_items} back />
              </div>
            </div>
          }
        ></Modal>
      )}
      {openView && (
        <Modal
          isOpen={openView}
          title={viewItem?.CUSTOMER_VENDOR_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={
                  viewItem?.CVO_REG == "2"
                    ? ["TOTAL AMOUNT", "REASON"]
                    : [
                        "TAXABLE AMOUNT",
                        "GST %	",
                        "IGST AMOUNT",
                        "CGST AMOUNT",
                        "SGST AMOUNT",
                        "TOTAL AMOUNT",
                        // "CREDIT/DEBIT TO (BANK ACCOUNT)",
                        "REASON",
                        "Narration",
                      ]
                }
                body={
                  <tr>
                    {viewItem?.CVO_REG == "2" ? (
                      <>
                        <td>{viewItem?.DEBIT_AMOUNT}</td>
                        <td>
                          {
                            creditNoteResons?.filter(
                              (v) => v?.id == viewItem?.NREASONS
                            )?.[0]?.res
                          }
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{viewItem?.TAXABLE_AMOUNT}</td>
                        <td>{viewItem?.GSTP}</td>
                        <td>{viewItem?.IGST_AMOUNT}</td>
                        <td>{viewItem?.CGST_AMOUNT}</td>
                        <td>{viewItem?.SGST_AMOUNT}</td>
                        <td>{viewItem?.DEBIT_AMOUNT}</td>
                        {/* <td>{viewItem?.BANK_ACCOUNT_NUMBER}</td> */}
                        <td>
                          {
                            creditNoteResons?.filter(
                              (v) => v?.id == viewItem?.NREASONS
                            )?.[0]?.res
                          }
                        </td>
                        <td>{viewItem?.REMARKS}</td>
                      </>
                    )}
                  </tr>
                }
              />
            </>
          }
        ></Modal>
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice
          previewRef={previewRef}
          listdata={{
            SR_NO: viewItem?.SID,
            SR_NO_DATE: viewItem?.NOTE_DATE,
            PO_NUMBER: viewItem?.REF_NO ,
            PO_DATE: viewItem?.REF_DATE,
            CVO_NAME: viewItem?.CUSTOMER_VENDOR_NAME || viewItem?.CVO_NAME,
            CVO_ADDRESS: viewItem?.BILLING_ADDRESS,
            B_STATE: viewItem?.B_STATE_CODE,
            CVO_TIN: viewItem?.CVO_TIN || "NA",
            SHIP_CVO_NAME: viewItem?.CUSTOMER_VENDOR_NAME || viewItem?.CVO_NAME,
            SHIP_CVO_ADDRESS: viewItem?.BILLING_ADDRESS,
            SHIP_B_STATE: viewItem?.B_STATE_CODE,
            SHIP_CVO_TIN: viewItem?.CVO_TIN || "NA",
          }}
          placeofsupply={viewItem?.B_STATE_CODE}
          statename={viewItem?.B_STATE_NAME}
          title={"CREDIT / DEBIT INVOICE"}
          table_header={
            viewItem?.CVO_REG == "2"
              ? tax_invoice_table_header2
              : tax_invoice_table_header
          }
          table_body={[viewItem]?.map((v, i) =>
            v?.CVO_REG == "2" ? (
              <tr key={i} className="inv-td" style={{ border: "none" }}>
                <td className="inv-td" style={{ border: "none" }}>
                  {i + 1}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.DEBIT_AMOUNT}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {
                    creditNoteResons?.filter(
                      (val) => val?.id == v?.NREASONS
                    )?.[0]?.res
                  }
                </td>
              </tr>
            ) : (
              <tr key={i} className="inv-td" style={{ border: "none" }}>
                <td className="inv-td" style={{ border: "none" }}>
                  {i + 1}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.TAXABLE_AMOUNT}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.GSTP}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.IGST_AMOUNT}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.CGST_AMOUNT}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.SGST_AMOUNT}
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.DEBIT_AMOUNT}
                </td>
                {/* <td className="inv-td" style={{ border: "none" }}>{v?.BANK_ACCOUNT_NUMBER}</td> */}
                <td className="inv-td" style={{ border: "none" }}>
                  {
                    creditNoteResons?.filter(
                      (val) => val?.id == v?.NREASONS
                    )?.[0]?.res
                  }
                </td>
                <td className="inv-td" style={{ border: "none" }}>
                  {v?.REMARKS || "-"}
                </td>
              </tr>
            )
          )}
          tax_total={[viewItem]
            ?.map((v) =>
              v?.TAXABLE_AMOUNT ? v?.TAXABLE_AMOUNT : v?.DEBIT_AMOUNT
            )
            .reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          igst_amount={[viewItem]
            ?.map((v) => v?.IGST_AMOUNT)
            .reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          cgst_amount={[viewItem]
            ?.map((v) => v?.CGST_AMOUNT)
            .reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          sgst_amount={[viewItem]
            ?.map((v) => v?.SGST_AMOUNT)
            .reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          net_total={viewItem?.DEBIT_AMOUNT}
        />
      </div>
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};

export default DebitNote;
