import React from "react";
import Input from "../inputs/Input";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

export const Pagination2 = ({
  top,
  bottom,
  totalCount,
  pageNo,
  setPageNo,
  entries,
  setEntries,
  SerchInput,
  setSearchInput,
  download = false,
  excelDown,
  PDFDown,
  count = 5,
  serchChange
}) => {
  const handlePrev = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };
  const handleNext = () => {
    const totalPages = Math.ceil(totalCount / entries);
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const generatePageNumbers = () => {
    const totalPages = Math.ceil(totalCount / entries);
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <div
            key={i}
            className={`page-item ${pageNo === i ? "active" : ""}`}
            style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </div>
        );
      }
    } else {
      if (pageNo <= 3) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        pageNumbers.push(
          <div
            key={totalPages}
            className={`page-item ${pageNo === totalPages ? "active" : ""}`}
            style={{ backgroundColor: pageNo === totalPages ? "#0971fe" : "" }}
            onClick={() => handlePageClick(totalPages)}
          >
            {totalPages}
          </div>
        );
      } else if (pageNo >= totalPages - 2) {
        pageNumbers.push(
          <div
            key={1}
            className={`page-item ${pageNo === 1 ? "active" : ""}`}
            style={{ backgroundColor: pageNo === 1 ? "#0971fe" : "" }}
            onClick={() => handlePageClick(1)}
          >
            1
          </div>
        );
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
      } else {
        pageNumbers.push(
          <div
            key={1}
            className={`page-item ${pageNo === 1 ? "active" : ""}`}
            style={{ backgroundColor: pageNo === 1 ? "#0971fe" : "" }}
            onClick={() => handlePageClick(1)}
          >
            1
          </div>
        );
        for (let i = pageNo - 1; i <= pageNo + 1; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        pageNumbers.push(
          <div
            key={totalPages}
            className={`page-item ${pageNo === totalPages ? "active" : ""}`}
            style={{ backgroundColor: pageNo === totalPages ? "#0971fe" : "" }}
            onClick={() => handlePageClick(totalPages)}
          >
            {totalPages}
          </div>
        );
      }
    }

    return pageNumbers;
  };
  return (
    <div className="table-container">
      {(totalCount > count ||  SerchInput?.length > 0 ) && top && (
        <div className="AO-2nd">
          <div className="AO-2nd-entries">
            {download && (
              <div
                className="epss-btn"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0",
                  marginRight:"10px",
                }}
              >
                <button
                  style={{
                    padding: "0.5em 1em",
                    fontSize: ".88em",
                    backgroundColor: "#e9e9e9",
                  }}
                  onClick={excelDown}
                >
                  Excel
                </button>
                <button
                  style={{
                    padding: "0.5em 1em",
                    fontSize: ".88em",
                    backgroundColor: "#e9e9e9",
                    
                  }}
                  onClick={PDFDown}
                >
                  PDF
                </button>
              </div>
            )}
            Show
            <select
              className="AO-2nd-ent-select"
              value={entries}
              onChange={(e) => {
                setEntries(e.target.value);
                setPageNo(1);
              }}
            >
              <option value="5">5</option>
              <option disabled={totalCount < 5} value="10">
                10
              </option>
              <option disabled={totalCount < 10} value="25">
                25
              </option>
              <option disabled={totalCount < 25} value="50">
                50
              </option>
              <option disabled={totalCount < 100} value="100">100</option>
              <option disabled={totalCount < 250} value="250">250</option>
              <option disabled={totalCount < 500} value="500">500</option>
              
              {/* <option disabled value="All">
                All
              </option> */}
            </select>
            entries
          </div>
          <div
            className="inp-wrap page-wrap"
            style={{ width: "25%", zIndex: "0" }}
          >
            <input
              className="inp"
              value={SerchInput}
              onChange={(e) => {
                if (setSearchInput) {
                  setSearchInput(e.target.value);
                } else {
                  serchChange(e)
                }
              }}
              placeholder={"Search..."}
            />
          </div>
        </div>
      )}

      {totalCount > count && bottom && (
        <div className="Page">
          <div className="show-list">
            Showing {entries * (pageNo - 1) + 1} to{" "}
            {entries * pageNo > totalCount ? totalCount : entries * pageNo} of{" "}
            {totalCount} entries
          </div>
          <div className="page-2nd">
            <div
              className={`page-prev ${pageNo === 1 ? "disabled" : ""}`}
              onClick={handlePrev}
            >
              <span className="page-icon1">Previous</span>
              <span className="page-icon2">
                <FaArrowLeft />
              </span>
            </div>
            {generatePageNumbers()}
            <div
              className={`page-next ${pageNo === 100 ? "disabled" : ""}`}
              onClick={handleNext}
            >
              <span className="page-icon1">Next</span>
              <span className="page-icon2">
                <FaArrowRight />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Pagination = ({
  top,
  bottom,
  totalEntries,
  pageValue,
  setPageValue,
  pageEnteries,
  setPageEnteries,
  generatePageNumbers,
  handlePrev,
  handleNext,
  SerchInput,
  setSearchInput,
}) => {
  return (
    <div className="table-container">
      {totalEntries > 5 && top && (
        <div className="AO-2nd">
          <div className="AO-2nd-entries">
            Show
            <select
              className="AO-2nd-ent-select"
              value={pageEnteries}
              onChange={(e) => {
                setPageEnteries(e.target.value);
                setPageValue(1);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option disabled value="All">
                All
              </option>
            </select>
            entries
          </div>
          <div style={{ width: "25%", zIndex: "0" }}>
            {" "}
            <Input
              value={SerchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={"Search..."}
            />
          </div>
        </div>
      )}

      {totalEntries > 5 && bottom && (
        <div className="Page">
          <div>
            Showing {pageEnteries * (pageValue - 1) + 1} to{" "}
            {pageEnteries * pageValue > totalEntries
              ? totalEntries
              : pageEnteries * pageValue}{" "}
            of {totalEntries} entries
          </div>
          <div className="page-2nd">
            <div
              className={`page-prev ${pageValue === 1 ? "disabled" : ""}`}
              onClick={handlePrev}
            >
              Previous
            </div>
            {generatePageNumbers()}
            <div
              className={`page-next ${pageValue === 100 ? "disabled" : ""}`}
              onClick={handleNext}
            >
              Next
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
